export const countriesdata = [
  { code: 'AD', label: 'Andorra', flag: '' },
  { code: 'AE', label: 'United Arab Emirates', flag: '' },
  { code: 'AF', label: 'Afghanistan', flag: '' },
  { code: 'AG', label: 'Antigua and Barbuda', flag: '' },
  { code: 'AI', label: 'Anguilla', flag: '' },
  { code: 'AL', label: 'Albania', flag: '' },
  { code: 'AM', label: 'Armenia', flag: '' },
  { code: 'AO', label: 'Angola', flag: '' },
  { code: 'AQ', label: 'Antarctica', flag: '' },
  { code: 'AR', label: 'Argentina', flag: '' },
  { code: 'AS', label: 'American Samoa', flag: '' },
  { code: 'AT', label: 'Austria', flag: '' },
  { code: 'AU', label: 'Australia', flag: '' },
  { code: 'AW', label: 'Aruba', flag: '' },
  { code: 'AX', label: 'Alland Islands', flag: '' },
  { code: 'AZ', label: 'Azerbaijan', flag: '' },
  { code: 'BA', label: 'Bosnia and Herzegovina', flag: '' },
  { code: 'BB', label: 'Barbados', flag: '' },
  { code: 'BD', label: 'Bangladesh', flag: '' },
  { code: 'BE', label: 'Belgium', flag: '' },
  { code: 'BF', label: 'Burkina Faso', flag: '' },
  { code: 'BG', label: 'Bulgaria', flag: '' },
  { code: 'BH', label: 'Bahrain', flag: '' },
  { code: 'BI', label: 'Burundi', flag: '' },
  { code: 'BJ', label: 'Benin', flag: '' },
  { code: 'BL', label: 'Saint Barthelemy', flag: '' },
  { code: 'BM', label: 'Bermuda', flag: '' },
  { code: 'BN', label: 'Brunei Darussalam', flag: '' },
  { code: 'BO', label: 'Bolivia', flag: '' },
  { code: 'BR', label: 'Brazil', flag: '' },
  { code: 'BS', label: 'Bahamas', flag: '' },
  { code: 'BT', label: 'Bhutan', flag: '' },
  { code: 'BV', label: 'Bouvet Island', flag: '' },
  { code: 'BW', label: 'Botswana', flag: '' },
  { code: 'BY', label: 'Belarus', flag: '' },
  { code: 'BZ', label: 'Belize', flag: '' },
  { code: 'CA', label: 'Canada', flag: '' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', flag: '' },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',

    flag: '',
  },
  { code: 'CF', label: 'Central African Republic', flag: '' },
  { code: 'CG', label: 'Congo, Republic of the', flag: '' },
  { code: 'CH', label: 'Switzerland', flag: '' },
  { code: 'CI', label: "Cote d'Ivoire", flag: '' },
  { code: 'CK', label: 'Cook Islands', flag: '' },
  { code: 'CL', label: 'Chile', flag: '' },
  { code: 'CM', label: 'Cameroon', flag: '' },
  { code: 'CN', label: 'China', flag: '' },
  { code: 'CO', label: 'Colombia', flag: '' },
  { code: 'CR', label: 'Costa Rica', flag: '' },
  { code: 'CU', label: 'Cuba', flag: '' },
  { code: 'CV', label: 'Cape Verde', flag: '' },
  { code: 'CW', label: 'Curacao', flag: '' },
  { code: 'CX', label: 'Christmas Island', flag: '' },
  { code: 'CY', label: 'Cyprus', flag: '' },
  { code: 'CZ', label: 'Czech Republic', flag: '' },
  { code: 'DE', label: 'Germany', flag: '' },
  { code: 'DJ', label: 'Djibouti', flag: '' },
  { code: 'DK', label: 'Denmark', flag: '' },
  { code: 'DM', label: 'Dominica', flag: '' },
  { code: 'DO', label: 'Dominican Republic', flag: '' },
  { code: 'DZ', label: 'Algeria', flag: '' },
  { code: 'EC', label: 'Ecuador', flag: '' },
  { code: 'EE', label: 'Estonia', flag: '' },
  { code: 'EG', label: 'Egypt', flag: '' },
  { code: 'EH', label: 'Western Sahara', flag: '' },
  { code: 'ER', label: 'Eritrea', flag: '' },
  { code: 'ES', label: 'Spain', flag: '' },
  { code: 'ET', label: 'Ethiopia', flag: '' },
  { code: 'FI', label: 'Finland', flag: '' },
  { code: 'FJ', label: 'Fiji', flag: '' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', flag: '' },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',

    flag: '',
  },
  { code: 'FO', label: 'Faroe Islands', flag: '' },
  { code: 'FR', label: 'France', flag: '' },
  { code: 'GA', label: 'Gabon', flag: '' },
  { code: 'GB', label: 'United Kingdom', flag: '' },
  { code: 'GD', label: 'Grenada', flag: '' },
  { code: 'GE', label: 'Georgia', flag: '' },
  { code: 'GF', label: 'French Guiana', flag: '' },
  { code: 'GG', label: 'Guernsey', flag: '' },
  { code: 'GH', label: 'Ghana', flag: '' },
  { code: 'GI', label: 'Gibraltar', flag: '' },
  { code: 'GL', label: 'Greenland', flag: '' },
  { code: 'GM', label: 'Gambia', flag: '' },
  { code: 'GN', label: 'Guinea', flag: '' },
  { code: 'GP', label: 'Guadeloupe', flag: '' },
  { code: 'GQ', label: 'Equatorial Guinea', flag: '' },
  { code: 'GR', label: 'Greece', flag: '' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',

    flag: '',
  },
  { code: 'GT', label: 'Guatemala', flag: '' },
  { code: 'GU', label: 'Guam', flag: '' },
  { code: 'GW', label: 'Guinea-Bissau', flag: '' },
  { code: 'GY', label: 'Guyana', flag: '' },
  { code: 'HK', label: 'Hong Kong', flag: '' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',

    flag: '',
  },
  { code: 'HN', label: 'Honduras', flag: '' },
  { code: 'HR', label: 'Croatia', flag: '' },
  { code: 'HT', label: 'Haiti', flag: '' },
  { code: 'HU', label: 'Hungary', flag: '' },
  { code: 'ID', label: 'Indonesia', flag: '' },
  { code: 'IE', label: 'Ireland', flag: '' },
  { code: 'IL', label: 'Israel', flag: '' },
  { code: 'IM', label: 'Isle of Man', flag: '' },
  { code: 'IN', label: 'India', flag: '' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',

    flag: '',
  },
  { code: 'IQ', label: 'Iraq', flag: '' },
  { code: 'IR', label: 'Iran, Islamic Republic of', flag: '' },
  { code: 'IS', label: 'Iceland', flag: '' },
  { code: 'IT', label: 'Italy', flag: '' },
  { code: 'JE', label: 'Jersey', flag: '' },
  { code: 'JM', label: 'Jamaica', flag: '' },
  { code: 'JO', label: 'Jordan', flag: '' },
  { code: 'JP', label: 'Japan', flag: '' },
  { code: 'KE', label: 'Kenya', flag: '' },
  { code: 'KG', label: 'Kyrgyzstan', flag: '' },
  { code: 'KH', label: 'Cambodia', flag: '' },
  { code: 'KI', label: 'Kiribati', flag: '' },
  { code: 'KM', label: 'Comoros', flag: '' },
  { code: 'KN', label: 'Saint Kitts and Nevis', flag: '' },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",

    flag: '',
  },
  { code: 'KR', label: 'Korea, Republic of', flag: '' },
  { code: 'KW', label: 'Kuwait', flag: '' },
  { code: 'KY', label: 'Cayman Islands', flag: '' },
  { code: 'KZ', label: 'Kazakhstan', lag: '' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",

    flag: '',
  },
  { code: 'LB', label: 'Lebanon', flag: '' },
  { code: 'LC', label: 'Saint Lucia', flag: '' },
  { code: 'LI', label: 'Liechtenstein', flag: '' },
  { code: 'LK', label: 'Sri Lanka', flag: '' },
  { code: 'LR', label: 'Liberia', flag: '' },
  { code: 'LS', label: 'Lesotho', flag: '' },
  { code: 'LT', label: 'Lithuania', flag: '' },
  { code: 'LU', label: 'Luxembourg', flag: '' },
  { code: 'LV', label: 'Latvia', flag: '' },
  { code: 'LY', label: 'Libya', flag: '' },
  { code: 'MA', label: 'Morocco', flag: '' },
  { code: 'MC', label: 'Monaco', flag: '' },
  { code: 'MD', label: 'Moldova, Republic of', flag: '' },
  { code: 'ME', label: 'Montenegro', flag: '' },
  { code: 'MF', label: 'Saint Martin (French part)', flag: '' },
  { code: 'MG', label: 'Madagascar', flag: '' },
  { code: 'MH', label: 'Marshall Islands', flag: '' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',

    flag: '',
  },
  { code: 'ML', label: 'Mali', flag: '' },
  { code: 'MM', label: 'Myanmar', flag: '' },
  { code: 'MN', label: 'Mongolia', flag: '' },
  { code: 'MO', label: 'Macao', flag: '' },
  { code: 'MP', label: 'Northern Mariana Islands', flag: '' },
  { code: 'MQ', label: 'Martinique', flag: '' },
  { code: 'MR', label: 'Mauritania', flag: '' },
  { code: 'MS', label: 'Montserrat', flag: '' },
  { code: 'MT', label: 'Malta', flag: '' },
  { code: 'MU', label: 'Mauritius', flag: '' },
  { code: 'MV', label: 'Maldives', flag: '' },
  { code: 'MW', label: 'Malawi', flag: '' },
  { code: 'MX', label: 'Mexico', flag: '' },
  { code: 'MY', label: 'Malaysia', flag: '' },
  { code: 'MZ', label: 'Mozambique', flag: '' },
  { code: 'NA', label: 'Namibia', flag: '' },
  { code: 'NC', label: 'New Caledonia', flag: '' },
  { code: 'NE', label: 'Niger', flag: '' },
  { code: 'NF', label: 'Norfolk Island', flag: '' },
  { code: 'NG', label: 'Nigeria', flag: '' },
  { code: 'NI', label: 'Nicaragua', flag: '' },
  { code: 'NL', label: 'Netherlands', flag: '' },
  { code: 'NO', label: 'Norway', flag: '' },
  { code: 'NP', label: 'Nepal', flag: '' },
  { code: 'NR', label: 'Nauru', flag: '' },
  { code: 'NU', label: 'Niue', flag: '' },
  { code: 'NZ', label: 'New Zealand', flag: '' },
  { code: 'OM', label: 'Oman', flag: '' },
  { code: 'PA', label: 'Panama', flag: '' },
  { code: 'PE', label: 'Peru', flag: '' },
  { code: 'PF', label: 'French Polynesia', flag: '' },
  { code: 'PG', label: 'Papua New Guinea', flag: '' },
  { code: 'PH', label: 'Philippines', flag: '' },
  { code: 'PK', label: 'Pakistan', flag: '' },
  { code: 'PL', label: 'Poland', flag: '' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', flag: '' },
  { code: 'PN', label: 'Pitcairn', flag: '' },
  { code: 'PR', label: 'Puerto Rico', lag: '' },
  { code: 'PS', label: 'Palestine, State of', flag: '' },
  { code: 'PT', label: 'Portugal', flag: '' },
  { code: 'PW', label: 'Palau', flag: '' },
  { code: 'PY', label: 'Paraguay', flag: '' },
  { code: 'QA', label: 'Qatar', flag: '' },
  { code: 'RE', label: 'Reunion', flag: '' },
  { code: 'RO', label: 'Romania', flag: '' },
  { code: 'RS', label: 'Serbia', flag: '' },
  { code: 'RU', label: 'Russian Federation', lag: '' },
  { code: 'RW', label: 'Rwanda', flag: '' },
  { code: 'SA', label: 'Saudi Arabia', flag: '' },
  { code: 'SB', label: 'Solomon Islands', flag: '' },
  { code: 'SC', label: 'Seychelles', flag: '' },
  { code: 'SD', label: 'Sudan', flag: '' },
  { code: 'SE', label: 'Sweden', flag: '' },
  { code: 'SG', label: 'Singapore', flag: '' },
  { code: 'SH', label: 'Saint Helena', flag: '' },
  { code: 'SI', label: 'Slovenia', flag: '' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', flag: '' },
  { code: 'SK', label: 'Slovakia', flag: '' },
  { code: 'SL', label: 'Sierra Leone', flag: '' },
  { code: 'SM', label: 'San Marino', flag: '' },
  { code: 'SN', label: 'Senegal', flag: '' },
  { code: 'SO', label: 'Somalia', flag: '' },
  { code: 'SR', label: 'Suriname', flag: '' },
  { code: 'SS', label: 'South Sudan', flag: '' },
  { code: 'ST', label: 'Sao Tome and Principe', flag: '' },
  { code: 'SV', label: 'El Salvador', flag: '' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', flag: '' },
  { code: 'SY', label: 'Syrian Arab Republic', flag: '' },
  { code: 'SZ', label: 'Swaziland', flag: '' },
  { code: 'TC', label: 'Turks and Caicos Islands', flag: '' },
  { code: 'TD', label: 'Chad', flag: '' },
  { code: 'TF', label: 'French Southern Territories', flag: '' },
  { code: 'TG', label: 'Togo', flag: '' },
  { code: 'TH', label: 'Thailand', flag: '' },
  { code: 'TJ', label: 'Tajikistan', flag: '' },
  { code: 'TK', label: 'Tokelau', flag: '' },
  { code: 'TL', label: 'Timor-Leste', flag: '' },
  { code: 'TM', label: 'Turkmenistan', flag: '' },
  { code: 'TN', label: 'Tunisia', flag: '' },
  { code: 'TO', label: 'Tonga', flag: '' },
  { code: 'TR', label: 'Turkey', flag: '' },
  { code: 'TT', label: 'Trinidad and Tobago', flag: '' },
  { code: 'TV', label: 'Tuvalu', flag: '' },
  { code: 'TW', label: 'Taiwan, Province of China', flag: '' },
  { code: 'TZ', label: 'United Republic of Tanzania', flag: '' },
  { code: 'UA', label: 'Ukraine', flag: '' },
  { code: 'UG', label: 'Uganda', flag: '' },
  { code: 'US', label: 'United States', flag: '' },
  { code: 'UY', label: 'Uruguay', flag: '' },
  { code: 'UZ', label: 'Uzbekistan', flag: '' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    flag: '',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    flag: '',
  },
  { code: 'VE', label: 'Venezuela', flag: '' },
  { code: 'VG', label: 'British Virgin Islands', flag: '' },
  { code: 'VI', label: 'US Virgin Islands', flag: '' },
  { code: 'VN', label: 'Vietnam', flag: '' },
  { code: 'VU', label: 'Vanuatu', flag: '' },
  { code: 'WF', label: 'Wallis and Futuna', flag: '' },
  { code: 'WS', label: 'Samoa', flag: '' },
  { code: 'XK', label: 'Kosovo', flag: '' },
  { code: 'YE', label: 'Yemen', flag: '' },
  { code: 'YT', label: 'Mayotte', flag: '' },
  { code: 'ZA', label: 'South Africa', flag: '' },
  { code: 'ZM', label: 'Zambia', flag: '' },
  { code: 'ZW', label: 'Zimbabwe', flag: '' },
];
