export const getQrCodeValue=(qrData)=>{
	return [`SPC`, `0200`, `1`,
			qrData.account!== '' ?qrData.account : ' ',
			`S`,
			qrData.creditor.name!== '' ? qrData.creditor.name.trim() : ' ',
			qrData.creditor.street!== '' ? qrData.creditor.street.trim() : ' ',
			qrData.creditor.buildingNumber!== '' ?  qrData.creditor.buildingNumber.trim() : ' ',
			qrData.creditor.postalCode!== '' ? qrData.creditor.postalCode.trim() : ' ',
			qrData.creditor.city!== '' ?  qrData.creditor.city.trim() : ' ',
			qrData.creditor.country!== '' ?  qrData.creditor.country.trim() : ' ',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			qrData.amount!== '' ? 	parseFloat(qrData.amount).toFixed(2).trim() : 0.00,
			qrData.currency!== '' ?  qrData.currency.trim() : ' ',
			'S',
			qrData.debtor.name!= ''?	qrData.debtor.name.trim() : ' ',
			qrData.debtor.street!= ''? qrData.debtor.street.trim() : ' ',
			qrData.debtor.buildingNumber!= ''?qrData.debtor.buildingNumber.trim(): ' ',
			qrData.debtor.postalCode!= ''?qrData.debtor.postalCode.trim(): ' ',
			qrData.debtor.city!= ''?qrData.debtor.city.trim(): ' ',
			qrData.debtor.country!= ''?qrData.debtor.country.trim(): ' ',
			qrData.reference.type!= ''?qrData.reference.type : ' ',
			qrData.reference.reference!= ''?qrData.reference.reference.trim(): ' ',
			qrData.unstructeredMessage!= ''? qrData.unstructeredMessage.trim(): ' ',
			'EPD'
		].join("\n");
}
