import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from './qrbill/transalation/de';
import en from './qrbill//transalation/en';
import it from './qrbill//transalation/it';
import fr from './qrbill//transalation/fr';
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en
            },
            de: {
                translation: de
            },
            it: {
                translation: it
            },
            fr: {
                translation: fr
            }
        },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;