
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { qrDataInterface } from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "8pt",
    lineHeight: "11pt",
    fontWeight: "bold"
  },
  text: {
    fontSize: "10pt",
    lineHeight: "11pt"
  }
}));
// interface Props {
//   qrData: qrDataInterface;
// }

const AdditionalInfo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "left", minHeight: "7mm" }}>
      <div className={classes.heading}>{t('additionalInformation')}</div>
      <div className={classes.text}>{props.qrData.unstructeredMessage} </div>
    </div>
  );
};



export default AdditionalInfo;
