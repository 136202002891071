/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
/* Material UI Styles */
import { makeStyles } from '@material-ui/core/styles';
/* Material UI Components */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoneyIcon from '@material-ui/icons/Money';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip';
import CountrySelector from '@qrtool-components/CountrySelector/CountrySelector';
import { qrDataInterface } from '@qrtool-interfaces';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckIcon from '@material-ui/icons/Check';
import { InoIBAN } from '@qrtool-utils/iban';
import { InoIbanSpecification } from '@qrtool-utils/ibanSpecification';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    cardArea: {
        padding: '0px 10px',
        fontWeight: 'normal',
        fontSize: '15px',
        userSelect: 'text',
    },
    title: {
        padding: '10px 4px',
        fontSize: 17,
    },
    divider: {
        width: '100%',
        height: '1px',
    },
    icons: {
        color: 'rgba(0,0,0,.54)'
    },
    alignSet: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    customTooltipError: {
        color: 'white',
        backgroundColor: '#ff5252',
        fontSize: 14
    },
    customTooltipPrimary: {
        color: 'white',
        backgroundColor: '#556cd6',
        fontSize: 14,
        maxWidth: 380
    },
    customTooltipGreen: {
        color: 'white',
        backgroundColor: '#4caf50 !important',
        fontSize: 14,
        maxWidth: 380
    },
    greenColorIcon: {
        color: '#4caf50 !important'
    }
}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
// }


const inoIBAN = new InoIBAN();
const PayableTo = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [qrDataAccount, setQrDataAccount] = useState(props.qrData.account);
    const [ibanSpecification, setIbanSpecification] = useState(inoIBAN.getCountryStrucutre("CH"));
    //you should follow this way.. use state should have type 
    const OnChangeQrdata = (key, value) => {
        props.setQrData({
            ...props.qrData,
            [`${key}`]: value
        })
    }

    const OnChangeQrCreditorData = (key, value) => {
        props.setQrData({
            ...props.qrData,
            creditor: { ...props.qrData.creditor, [`${key}`]: value }
        })
    }


    const _onChangeAccountNumber = (e) => {

        setIbanSpecification(inoIBAN.getCountryStrucutre(e.target.value))
        console.log(ibanSpecification.isValid(e.target.value));
        setQrDataAccount(e.target.value);
        if (e.target.value.length <= 21) {
            OnChangeQrdata('account', e.target.value)
        }
    }

    const OnChangeCreditorName = (e) => {
        OnChangeQrCreditorData('name', e.target.value)
    }

    const OnChangeCreditorStreet = (e) => {
        OnChangeQrCreditorData('street', e.target.value)
    }

    const OnChangeCreditorBuildingNumber = (e) => {
        OnChangeQrCreditorData('buildingNumber', e.target.value)
    }
    const OnChangeCreditorPostalCode = (e) => {
        OnChangeQrCreditorData('postalCode', e.target.value)
    }
    const OnChangeCreditorTown = (e) => {
        OnChangeQrCreditorData('city', e.target.value)
    }
    const OnChangeCreditorCountry = (value) => {
        OnChangeQrCreditorData('country', value)
    }


    const renderError = () => {
        console.log(inoIBAN.getCountryStrucutre(qrDataAccount))
        return !((
            (props.qrData.reference.type === 'SCOR' && inoIBAN.isQRIBAN(qrDataAccount)) ||
            (props.qrData.reference.type === 'QRR' && inoIBAN.permittedIBAN(qrDataAccount))
        ));
    }
    return (
        // <Card style={{ minHeight: 480 }}>
            <div className={classes.cardArea}>
                <Grid container>
                    <Grid
                        item
                        sm={10}
                        xs={10}
                        style={{ padding: '10px 0px' }}
                    >
                        <Typography component="h6" variant="h6" style={{ float: 'left' }}>
                            {t('payableTo')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        xs={2}
                        className={classes.alignSet}
                        style={{ padding: 10 }}
                    >
                        <PersonIcon
                            className={classes.icons}
                        />
                        <ArrowBackIcon
                            className={classes.icons}
                        />
                        <MoneyIcon
                            className={classes.icons}
                        />
                    </Grid>
                </Grid>
                <Divider
                    className={classes.divider}
                    style={{ marginBottom: '5px' }}
                />
                <Grid container spacing={1}>
                    {/* <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_IBAN"
                            autoComplete="new-password"
                            label={props.qrData.reference.type === 'QRR' || props.qrData.reference.type === 'NON' ? "IBAN *" : 'QR-IBAN *'}
                            variant={"standard"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_IBAN",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={<div>
                                <span style={{ float: 'right' }}> {qrDataAccount?.length + '/ ' + ibanSpecification?.length}</span>
                            </div>}
                            value={qrDataAccount}
                            error={renderError}
                            onChange={_onChangeAccountNumber}
                            InputProps={{
                                endAdornment: (
                                    qrDataAccount?.length > 21 ? <InputAdornment position="end">
                                        <Tooltip title="Check digit is wrong"
                                            aria-label="info"
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltipError,
                                            }}
                                        >
                                            <ReportProblemIcon color="error" />
                                        </Tooltip>
                                    </InputAdornment>
                                        :
                                        qrDataAccount?.length === 21 && <InputAdornment position="end">
                                            <Tooltip title="Check digit is correct"
                                                aria-label="info"
                                                placement="top"
                                                classes={{
                                                    tooltip: classes.customTooltipGreen,
                                                }}
                                            >
                                                <CheckIcon color="primary" className={classes.greenColorIcon} />
                                            </Tooltip>
                                        </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >


                        <Tooltip title={'   IBAN or QR-IBAN of the ceditor.Fixed length:21 alphanumeric characters,Only IBANs with CH or LI country coded country code permitted.'}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid> */}
                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_name"
                            autoComplete="new-password"
                            label={t('Name') + "*"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_name",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.creditor.name}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeCreditorName}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('creditorNameInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_street"
                            autoComplete="new-password"
                            label={t("Street")}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_street",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.creditor.street}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeCreditorStreet}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('creditorStreetInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xs={4}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_number"
                            autoComplete="new-password"
                            label={t("Number")}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_number",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.creditor.buildingNumber}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeCreditorBuildingNumber}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('creditorNumberInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xs={4}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_postal_code"
                            autoComplete="new-password"
                            label={t('postalCode') + '*'}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_postal_code",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.creditor.postalCode}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeCreditorPostalCode}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('creditorPostalCodeInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_town"
                            autoComplete="new-password"
                            label={t('town') + "*"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_town",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.creditor.city}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeCreditorTown}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('creditorTownInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <CountrySelector
                            recent={['CH']}
                            value={props.qrData.creditor.country}
                            handleChangeCountry={OnChangeCreditorCountry}
                            label={t('country') + '*'}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('crditorCountryInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                </Grid>
            </div>
        // </Card>
    );
};



export default PayableTo;
