import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {qrDataInterface} from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  heading:{
    fontSize:"6pt",
    lineHeight:"9pt",
    fontWeight:"bold"
  },
  text:{
    fontSize:"8pt",
    lineHeight:"9pt"
  }
}));

// interface Props {
//   qrData: qrDataInterface;
// }

const PayableBy = (props ) => {
const classes = useStyles();
const { t } = useTranslation();
  return (
   <div style={{textAlign:"left"}}>
     <div className={classes.heading}>{props.qrData.checks["showPaybleBy"]=== true? t('payableBy'):t('payableByNameOrAddress')}</div>
     {props.qrData.checks["showPaybleBy"]=== true?<>
    <div className={classes.text}>{props.qrData.debtor['name']}</div>
    <div className={classes.text}>{props.qrData.debtor['street']} {props.qrData.debtor['buildingNumber']}</div>
    <div className={classes.text}>{props.qrData.debtor['postalCode']} {props.qrData.debtor['city']}</div>
    </>:
    <svg x="9.7mm" y="66.7mm" width="40.6mm" height="15.6mm"><rect x="0.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect></svg>
    }
   </div>
  );
};



export default PayableBy;
