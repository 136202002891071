import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PayableBy from "./Components/PayableBy";
import AccountPart from './Components/AccountPart';
import PayableTo from './Components/PayableTo';
import PaymentInformation from './Components/PaymentInformation';
// import { qrDataInterface } from '@qrtool-interfaces';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(() => ({


}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
// }


export default function InputDataPanel(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={6}>
                    <PaymentInformation qrData={props.qrData} setQrData={props.setQrData} />
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={6}>
                        <Card style={{ minHeight: 480 }}>
                    <PayableTo qrData={props.qrData} setQrData={props.setQrData} />
                    <PayableBy qrData={props.qrData} setQrData={props.setQrData} />
                    </Card>
                </Grid>
                {/* <Grid
                    item
                    lg={4}
                    sm={12}
                    xs={12}>
                    <PayableBy qrData={props.qrData} setQrData={props.setQrData} />
                </Grid> */}
            </Grid>
            <Grid container spacing={1}></Grid>
        </React.Fragment>
    );
}
