const de = {
    'paymentPart': 'Zahlteil',
    'accountPayableTo': 'Konto / Zahlbar an',
    'reference': 'Referenz',
    'additionalInformation': 'Zusätzliche Informationen',
    'furtherInformation': 'Weitere Informationen',
    'currency': 'Währung',
    'amount': 'Betrag',
    'receipt': 'Empfangsschein',
    'acceptancePoint': 'Annahmestelle',
    'separateBeforePayingIn': 'Vor der Einzahlung abzutrennen',
    'payableBy': 'Zahlbar durch',
    'payableByNameOrAddress': 'Zahlbar durch (Name/Adresse)',
    'inFavourOf': 'Zugunsten',
    "paymentInfomation": "Zahlungsinformationen",
    "variant": "Variante",
    "QR-IBAN": "QR-IBAN",
    "unstructuredMsg": "Unstrukturierte Nachricht",
    "QR-reference": "QR-Referenz",
    "CreditorReference": "Gläubiger-Referenz",
    "payableTo": "Zahlbar an",
    "Name": "Name",
    "Street": "Strasse",
    "Number": "Nummer",
    "postalCode": "Postleitzahl",
    "town": "Ort",
    "country": "Land",
    "qrIBANInfoTextOfPemitted": "IBAN des Zahlungsempfängers.Feste Länge:21, Nur IBANs mit CH- oder LI-Ländercode erlaubt.",
    "qrIBANInfoTextOflength": "IBAN oder QR-IBAN des Zahlungsempfängers. Anzahl der Zahlen für {{countryCode}}-IBAN: {{length}}",
    "paymentInfomationHeaderInfoText": "Zahlungsinformationen enthalten Daten, die mit der Zahlung verpfändet werden.",
    "qrVarantInfoText1": "Es gibt drei Varianten der QR-Rechnung:",
    "qrVarantInfoText2": "1). QR-IBAN mit QR-Referenz - Ersetzt den orangen Einzahlungsschein",
    "qrVarantInfoText3": "2). IBAN mit Gläubigerreferenz - Neue Zahlungsvariante",
    "qrVarantInfoText4": "3). IBAN ohne Referenz - Ersetzt den roten Einzahlungsschein",
    "currencyInfoext": "Die Zahlungswährung. Es sind nur CHF, EUR, USD erlaubt.",
    "amountInfoText": "Der Zahlungsbetrag.Das Zahlungselement ist ohne führende Nullen zu erfassen, inklusive Dezimaltrennzeichen und zwei Nachkommastellen. Dezimal Maximal 12 Ziffern zulässig, einschliesslich Dezimaltrennzeichen. Als Dezimaltrennzeichen sind nur Dezimalpunkte (.) erlaubt.Für eine Benachrichtigung verwenden Sie den Betrag 0,00.",
    "unStructuredMassageInfoText": "Unstrukturierte Informationen können zur Angabe des Zahlungszwecks oder für zusätzliche Textinformationen zu den Zahlungen mit einem strukturierten Verweis verwendet werden. Unstrukturierte Meldungen und Buchungsanweisungen können insgesamt bis zu 140 Zeichen enthalten.",
    "qrReferenceText": "Einige Finanzinstitute empfehlen die Verwendung der BESR-ID in den ersten sechs Ziffern der QR-Referenz während der Parallelphase von Einzahlungsschein und QR-Rechnung, um mögliche Ablehnungen und falsche Gutschriften bei der Empfängerbank zu vermeiden, wenn eine QR-Rechnung fälschlicherweise als oranger Einzahlungsschein bezahlt wird.",
    "creditorNameInfoText": "Name oder Firma des Zahlungsempfängers entsprechend der Kontobezeichnung.Kommentar: stimmt immer mit dem Kontoinhaber überein. Maximal 70 Zeichen sind erlaubt. Vorname (optional, wenn vorhanden) + Nachname oder Firmenname.",
    "creditorStreetInfoText": "Strasse/Postfach der Adresse des Gläubigers. Maximal 70 Zeichen zulässig.",
    "creditorNumberInfoText": "Hausnummer aus der Adresse des Zahlungsempfängers. 16 Zeichen zulässig.",
    "creditorPostalCodeInfoText": "Postleitzahl aus der Adresse des Zahlungsempfängers. Maximal 16 Zeichen zulässig. Die Postleitzahl muss ohne Ländervorwahl angegeben werden.",
    "creditorTownInfoText": "Ort aus der Adresse des Gläubigers. Maximal 35 Zeichen zulässig.",
    "crditorCountryInfoText": "Land aus der Adresse des Zahlungsempfängers.",
    "debtorNameInfoText": "Name des Endschuldners oder der Firma. Maximal 70 Zeichen zulässig. Vorname (optional, falls vorhanden) + Nachname oder Firmenname.",
    "debtorStreetInfoText": "Strasse/Postfach von der Adresse des Endschuldners. Maximal 70 Zeichen zulässig.",
    "debtorNumberInfoText": "Hausnummer der endgültigen Zahlungspflichtigenadresse.",
    "debtorPostalCodeInfoText": "Postleitzahl der Adresse des endgültigen Zahlungspflichtigen. Die Postleitzahl ist immer ohne vorangestellten Ländercode anzugeben.",
    "debtorTownInfoText": "Ort der Adresse des endgültigen Zahlungspflichtigen.",
    "debtorCountryInfoText": "Land der Adresse des endgültigen Zahlungspflichtigen.",
    "qrrIBANErrorMessage": "Nur CH und LI erlaubt",
    "IBANErrorMessage": "Ungültige IBAN",
    "IBANvalidMessage": "Gültige IBAN",

    "SCOR": "QR-IBAN mit QR-Referenz (formal ESR-Referenz) (SCOR)",
    "QRR": "IBAN mit Gläubigerreferenz (ISO 11649) (QRR)",
    "NON": "IBAN ohne Referenz (NON)",

    "CHF": "CHF",
    "USD": "USD",
    "EUR": "EUR",

    "referenceTypeInfoText": "The structured reference is either QR reference or an ISO 11649 Creditor reference.Must contain a QR reference is a QR IBAN is used.ISO 11649 Creditor reference can optionaly be entered if the IBAN is used.QR reference: 27 numeric characters.Creditors reference(ISO-11649): max 25 alphanumeric characters.",
    "IBAN": 'IBAN',

    'pageHeaderLine1': 'Generieren Sie Ihre Schweizer QR-Invoice',
    'pageHeaderLine2': 'Ein einfacher und schneller Weg, um Ihren Schweizer QR-Code zu generieren. Es ist sicher, konform und kostenlos!',
    'pageHeaderLine3': 'WIR SPEICHERN KEINE IHRER PERSÖNLICHEN DATEN VON DIESEM TOOL',
    "qrBillPdf": "QR-Rechnung (PDF)",
    "qrBillSvg": "QR-Rechnung (SVG)",
    "qrOnlySvg": "Nur QR (SVG)",
    "printQrBill": "QR-Rechnung drucken",
    "downloadMasterDataToLocalFile": "Stammdaten in lokale Datei herunterladen",
    "importFromMasterDataFile": "Importieren aus Stammdaten-Datei",
    "qrBillLanguage":"Rechnung Sprache"

}


export default de;
