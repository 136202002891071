import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AdditionalInfo, AccountPayableTo, PayableBy, ReferenceCode } from "./Components";
import { qrDataInterface } from '@qrtool-interfaces';

const useStyles = makeStyles((theme) => ({
  lineHeightPayment:{
    // lineHeight:"13pt",
    marginBottom:"13pt"
  }
}));

// interface Props {
//   qrData: qrDataInterface;
// }

const PayableByInfo = (props) => {
const classes = useStyles();
  return (

      <div style={{height:"7mm"}}>
       <div className={classes.lineHeightPayment}><AccountPayableTo qrData={props.qrData}/></div>
       { props.qrData.reference.type === 'NON'? "" :
       <div className={classes.lineHeightPayment}><ReferenceCode qrData={props.qrData}/></div> }
        <div className={classes.lineHeightPayment} ><AdditionalInfo qrData={props.qrData}/></div>
        <div className={classes.lineHeightPayment}><PayableBy qrData={props.qrData}/></div>
      </div>

  );
};



export default PayableByInfo;
