import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { qrDataInterface } from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "8pt",
    lineHeight: "11pt",
    fontWeight: "bold"
  },
  text: {
    fontSize: "10pt",
    lineHeight: "11pt"
  }
}));

// interface Props {
//   qrData: qrDataInterface;
// }

const AccountPayableTo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "left", minHeight: "10mm" }}>
      <div className={classes.heading}>{t('accountPayableTo')}</div>
      <div className={classes.text}>{props.qrData.account}</div>
      <div className={classes.text}>{props.qrData.creditor["name"]}</div>
      <div className={classes.text}>{props.qrData.creditor["street"]} {props.qrData.creditor["buildingNumber"]}</div>
      <div className={classes.text}>{props.qrData.creditor["postalCode"]} {props.qrData.creditor["city"]}</div>
    </div>
  );
};



export default AccountPayableTo;
