import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

}));

function TextFieldNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;


  const onValueChange = (values) => {
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  }
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator
      isNumericString
    // prefix="$"
    />
  );
}

TextFieldNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextFieldNumberFormatCustom;