import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import PublishIcon from '@material-ui/icons/Publish';
import { jsPDF } from 'jspdf';
// import { qrDataInterface } from '@qrtool-interfaces';
import { renderToString } from "react-dom/server";
import { QRCode } from "react-qr-svg";
import { getQrCodeValue } from '@qrtool-utils/utils';
import { useTranslation } from "react-i18next";
// import blobStream from "blobStream";
import PDFDocument from 'pdfkit';
import SVGtoPDF from "svg-to-pdfkit"
import "./register-files"
import blobStream  from "blob-stream";
import FileSaver  from 'file-saver';
PDFDocument.prototype.addSVG = function (svg, x, y, options) {
	return SVGtoPDF(this, svg, x, y, options), this;
};

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
		textTransform: 'none'
	},
}));

// interface Props {
// 	qrData: qrDataInterface;
// }


export default function DownloadExportPanel(props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const qrImage = renderToString(
		<QRCode
			bgColor="#FFFFFF"
			fgColor="#000000"
			level="M"
			value={getQrCodeValue(props.qrData)}
		/>
	);

	const receiptSvg = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="qr-bill-svg"  width="210mm" height="110mm" role="img" font-family="Frutiger, Helvetica, Arial, Liberation Sans, sans-serif">
<text x="105mm" y="3mm" text-anchor="middle" font-size="6pt" line-height="9pt"></text>
<line x1="0mm" y1="5mm" x2="202.5mm" y2="5mm" stroke="black" strokeWidth="0.1mm"></line>
<line x1="204.8mm" y1="5mm" x2="210mm" y2="5mm" stroke="black" strokeWidth="0.1mm"></line>
<line x1="62mm" y1="5mm" x2="62mm" y2="102.5mm" stroke="black" strokeWidth="0.1mm"></line>
<line x1="62mm" y1="104.8mm" x2="62mm" y2="110mm" stroke="black" strokeWidth="0.1mm"></line>
<svg x="202mm" y="3.5mm" width="3mm" height="3mm" preserveAspectRatio="none">
	<svg fill="none" stroke="black" strokeWidth="0.25mm" strokeLinecap="round" strokeLinejoin="round">
		<circle cx="2.25mm" cy="2.25mm" r="0.375mm"></circle>
		<circle cx="2.25mm" cy="0.7500000000000001mm" r="0.375mm"></circle>
		<line x1="1.9848349570550445mm" y1="1.9848349570550448mm" x2="1.5mm" y2="1.5mm"></line>
		<line x1="1.125mm" y1="1.125mm" x2="0.5mm" y2="0.4999999999999998mm"></line>
		<line x1="1.9848349570550448mm" y1="1.0151650429449552mm" x2="0.4999999999999998mm" y2="2.5mm"></line>
	</svg>
</svg>
<svg x="60.5mm" y="102mm" width="3mm" height="3mm" preserveAspectRatio="none">
	<svg fill="none" stroke="black" strokeWidth="0.25mm" strokeLinecap="round" strokeLinejoin="round">
		<circle cx="0.7499999999999999mm" cy="2.25mm" r="0.375mm"></circle>
		<circle cx="2.25mm" cy="2.25mm" r="0.375mm"></circle>
		<line x1="1.0151650429449552mm" y1="1.9848349570550448mm" x2="1.5mm" y2="1.5mm"></line>
		<line x1="1.875mm" y1="1.125mm" x2="2.5mm" y2="0.5mm"></line>
		<line x1="1.9848349570550448mm" y1="1.9848349570550448mm" x2="0.5mm" y2="0.4999999999999998mm"></line>
	</svg>
</svg>
<svg x="0mm" y="5mm" width="62mm" height="105mm" className="receipt">
	<svg x="5mm" y="5mm" width="52mm" height="95mm" className="innerReceipt">
		<text x="0mm" y="3mm" font-size="11pt" font-weight="bold">${t('receipt')}</text>
		<text x="0mm" y="3.65mm">
			<tspan x="0mm" dy="18pt" font-size="6pt" line-height="9pt" font-weight="bold">${t('accountPayableTo')}</tspan>
			<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.account?.toString()?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}</tspan>
			<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.creditor["name"]}</tspan>
			<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.creditor["street"]} ${props.qrData.creditor["buildingNumber"]}</tspan>
			<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.creditor["postalCode"]} ${props.qrData.creditor["city"]}</tspan>
			<tspan x="0mm" dy="18pt" font-size="6pt" line-height="9pt" font-weight="bold">${t('reference')}</tspan>
			<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.reference.reference}</tspan>
			<tspan x="0mm" dy="18pt" font-size="6pt" line-height="9pt" font-weight="bold">${props.qrData.checks["showPaybleBy"] === true ? t('payableBy') : t('payableByNameOrAddress')}</tspan>
			${props.qrData.checks["showPaybleBy"] === true ? `<tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.debtor['name']}</tspan><tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.debtor['street']} ${props.qrData.debtor['buildingNumber']}</tspan><tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.debtor['postalCode']} ${props.qrData.debtor['city']}</tspan><tspan x="0mm" dy="9pt" font-size="8pt" line-height="9pt">${props.qrData.debtor['country']}</tspan>` :
			``}

		</text>
		${props.qrData.checks["showPaybleBy"] === false ? `<svg x="0mm" y="27mm" width="40.6mm" height="15.6mm"><rect x="0.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect></svg>` : ``}
		<text x="0mm" y="66mm" font-size="6pt" line-height="9pt" font-weight="bold">${t('currency')}</text>
		<text x="0mm" y="70mm" font-size="8pt" line-height="9pt">${props.qrData.currency}</text>
		<text x="22mm" y="66mm" font-size="6pt" line-height="9pt" font-weight="bold">${t('amount')}</text>
		${props.qrData.checks["showAmount"] === true ? `<text x="22mm" y="70mm" font-size="8pt" line-height="9pt">${Number(props.qrData.amount).toLocaleString()} </text>` :
			`<svg x="9.7mm" y="66.7mm" width="30.6mm" height="6.6mm"><rect x="0.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect></svg>`
		}
		<text x="52mm" y="80mm" text-anchor="end" font-size="6pt" line-height="9pt" font-weight="bold">${t('acceptancePoint')}</text>
	</svg>
</svg>
<svg x="62mm" y="5mm" width="148mm" height="105mm" className="payment">
	<svg x="5mm" y="5mm" width="138mm" height="95mm" className="innerPayment">
		<text x="0mm" y="3mm" font-size="11pt" font-weight="bold">${t('paymentPart')}</text>
		<svg id="qr_code_svg" width="46mm" height="46mm" x="0mm" y="12mm">
		${qrImage}
		<svg width="7mm" height="7mm" x="19.5mm" y="19.5mm" viewBox="0 0 19.8 19.8" xmlSpace="preserve">
			<polygon points="18.3,0.7 1.6,0.7 0.7,0.7 0.7,1.6 0.7,18.3 0.7,19.1 1.6,19.1 18.3,19.1 19.1,19.1 19.1,18.3 19.1,1.6 19.1,0.7 " fill="black"></polygon>
			<rect x="8.3" y="4" width="3.3" height="11" fill="white"></rect>
			<rect x="4.4" y="7.9" width="11" height="3.3" fill="white"></rect>
			<polygon points="0.7,1.6 0.7,18.3 0.7,19.1 1.6,19.1 18.3,19.1 19.1,19.1 19.1,18.3 19.1,1.6 19.1,0.7 18.3,0.7 1.6,0.7 0.7,0.7 " fill="none" stroke="white" strokeWidth="1.4357" strokeMiterlimit="10"></polygon>
		</svg>
		</svg>
		<text x="0mm" y="66mm" font-size="8pt" line-height="11pt" font-weight="bold">${t('currency')}</text>
		<text x="0mm" y="70mm" font-size="10pt" line-height="11pt">${props.qrData.currency}</text>
		<text x="22mm" y="66mm" font-size="8pt" line-height="11pt" font-weight="bold">${t('amount')}</text>

		${props.qrData.checks["showAmount"] === true ? `<text x="22mm" y="70mm" font-size="10pt" line-height="11pt">${Number(props.qrData.amount).toLocaleString()} </text>` :
			` <svg x="9.7mm" y="66.7mm" width="30.6mm" height="6.6mm"><rect x="0.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect></svg>`
		}

		<text x="51mm" y="-4.76mm">
			<tspan x="51mm" dy="22pt" font-size="8pt" line-height="11pt" font-weight="bold">${t('accountPayableTo')}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.account?.toString()?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.creditor["name"]}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.creditor["street"]} ${props.qrData.creditor["buildingNumber"]}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.creditor["postalCode"]} ${props.qrData.creditor["city"]}</tspan>
			<tspan x="51mm" dy="22pt" font-size="8pt" line-height="11pt" font-weight="bold">${t('reference')}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.reference.reference}</tspan>
			<tspan x="51mm" dy="22pt" font-size="8pt" line-height="11pt" font-weight="bold">${t('additionalInformation')}</tspan>
			<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.unstructeredMessage}</tspan>
			<tspan x="51mm" dy="22pt" font-size="8pt" line-height="11pt" font-weight="bold">${props.qrData.checks["showPaybleBy"] === true ? t('payableBy') : t('payableByNameOrAddress')}</tspan>

			${props.qrData.checks["showPaybleBy"] === true ? `<tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.debtor['name']}</tspan><tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.debtor['street']} ${props.qrData.debtor['buildingNumber']}</tspan><tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.debtor['postalCode']} ${props.qrData.debtor['city']}</tspan><tspan x="51mm" dy="11pt" font-size="10pt" line-height="11pt">${props.qrData.debtor['country']}</tspan>` :
			``}
		</text>
		${props.qrData.checks["showPaybleBy"] === false ? `<svg x="51mm" y="32mm" width="40.6mm" height="15.6mm"><rect x="0.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="0.3mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="0.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect><rect x="37.3mm" y="15.035416666666668mm" width="3mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="40.03541666666666mm" y="12.3mm" width="0.2645833333333333mm" height="3mm" fill="black" stroke="none"></rect></svg>` : ``}
		<text x="0mm" y="85.18mm"></text>
	</svg>
</svg>
</svg>`;

	const svgToPng = (svg, callback) => {
		const url = getSvgUrl(svg);
		svgUrlToPng(url, (imgData) => {
			callback(imgData);
			URL.revokeObjectURL(url);
		});
	}


	const getSvgUrl = (svg) => {
		return URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
	}


	const svgUrlToPng = (svgUrl, callback) => {
		const svgImage = document.createElement('img');
		svgImage.setAttribute("id", "svgimage-to-download");
		document.body.appendChild(svgImage);
		svgImage.onload = function () {
			const canvas = document.createElement('canvas');
			canvas.width = svgImage.clientWidth;
			canvas.height = svgImage.clientHeight;
			const canvasCtx = canvas.getContext('2d');
			canvasCtx.drawImage(svgImage, 0, 0);
			const imgData = canvas.toDataURL('image/png');
			callback(imgData);
		};
		svgImage.src = svgUrl;
	}

	const downloadPDF = () => {
		const doc = new PDFDocument();
		const stream = doc.pipe(blobStream());
		console.log(stream);
		SVGtoPDF(doc, receiptSvg, 0, 0, {});
		// doc.addSVG(receiptSvg, 0, 0, {});
		doc.end();
		stream.on('finish', function() {
			// get a blob you can do whatever you like with
			console.log("finisehed");
			const blob = stream.toBlob('application/pdf');
		  
			// or get a blob URL for display in the browser
			const url = stream.toBlobURL('application/pdf');
			console.log(url);
			FileSaver.saveAs(url)
		  });
		  
		// svgToPng(receiptSvg, (imgData) => {
		// 	var doc = new jsPDF('p', 'px', 'a4');
		// 	const imgProps = doc.getImageProperties(imgData);
		// 	// doc.addImage(imgData, 'PNG', 0, 550, 585, 300);//793,415
		// 	var width = doc.internal.pageSize.getWidth();
		// 	var height = (imgProps.height * width) / imgProps.width;
		// 	doc.addImage(imgData, 'PNG', 0, 400, width, height);
		// 	doc.save('QR Receipt.pdf');
		// 	var element = document.getElementById("svgimage-to-download");
		// 	element.parentNode.removeChild(element);
		// });
	}

	function downloadSVG() {
		var dl = document.createElement("a");
		// document.body.appendChild(dl);
		dl.setAttribute("href", getSvgUrl(receiptSvg));
		dl.setAttribute("download", "QR Receipt.svg");
		dl.click();
	}


	function downloadQRSvg() {
		let qrCode = `<svg  xmlns="http://www.w3.org/2000/svg" version="1.1" width="46mm" height="46mm" x="0mm" y="12mm" role="img" font-family="Frutiger, Helvetica, Arial, Liberation Sans, sans-serif">>
	${qrImage}
		<svg width="7mm" height="7mm" x="19.5mm" y="19.5mm" viewBox="0 0 19.8 19.8" xmlSpace="preserve">
		<polygon points="18.3,0.7 1.6,0.7 0.7,0.7 0.7,1.6 0.7,18.3 0.7,19.1 1.6,19.1 18.3,19.1 19.1,19.1 19.1,18.3 19.1,1.6 19.1,0.7 " fill="black"></polygon>
		<rect x="8.3" y="4" width="3.3" height="11" fill="white"></rect>
		<rect x="4.4" y="7.9" width="11" height="3.3" fill="white"></rect>
		<polygon points="0.7,1.6 0.7,18.3 0.7,19.1 1.6,19.1 18.3,19.1 19.1,19.1 19.1,18.3 19.1,1.6 19.1,0.7 18.3,0.7 1.6,0.7 0.7,0.7 " fill="none" stroke="white" strokeWidth="1.4357" strokeMiterlimit="10"></polygon>
		</svg>
	</svg>`;
		var dl = document.createElement("a");
		dl.setAttribute("href", getSvgUrl(qrCode));
		dl.setAttribute("download", "QR Code.svg");
		dl.click();

	}


	function print() {
		var divContents = receiptSvg;//document.getElementById("GFG").innerHTML;
		var a = window.open('', '', 'height=500, width=500');
		a.document.write('<html>');
		a.document.write('<body >');
		a.document.write(divContents);
		a.document.write('</body></html>');
		a.document.close();
		a.print();
		// let popupWinindow;
		// let innerContents = receiptSvg
		// // console.log(innerContents);
		// popupWinindow = window.open('', '_blank', 'width=1000,height=1000,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
		// popupWinindow.document.open();
		// popupWinindow.document.write('<html><head><style></style></head><body onload="window.print()">' + innerContents + '</html>');
		// popupWinindow.document.close();
	}


	return (
		<div style={{ display: "flex", flexWrap: 'wrap' }}>
			<Button
				variant="contained"
				color="primary"
				size="small"
				onClick={downloadPDF}
				className={classes.button}
				startIcon={<GetAppIcon />}
			>
				{t('qrBillPdf')}
			</Button>
			<Button
				variant="contained"
				color="primary"
				onClick={downloadSVG}
				size="small"
				className={classes.button}
				startIcon={<GetAppIcon />}
			>
				{t('qrBillSvg')}
			</Button>
			<Button
				variant="contained"
				color="primary"
				size="small"
				onClick={downloadQRSvg}
				className={classes.button}
				startIcon={<GetAppIcon />}
			>
				{t('qrOnlySvg')}
			</Button>
			<Button
				variant="contained"
				color="primary"
				size="small"
				onClick={print}
				className={classes.button}
				startIcon={<PrintIcon />}
			>
				{t('printQrBill')}
			</Button>
			{/* <Button
				variant="contained"
				color="primary"
				size="small"
				className={classes.button}
				startIcon={<GetAppIcon />}
			>
				{t('downloadMasterDataToLocalFile')}
			</Button>
			<Button
				variant="contained"
				color="primary"
				size="small"
				className={classes.button}
				startIcon={<PublishIcon />}
			>
				{t('importFromMasterDataFile')}
			</Button> */}
		</div >
	);
}
