import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Receipt,PaymentPart,PayableByInfo,FurtherInfoSection } from "./Components"
import { qrDataInterface  } from '@qrtool-interfaces';


const useStyles = makeStyles(() => ({
qrMainBlock:{
    display:"flex",
    maxWidth:"210mm !important",
    minWidth:"210mm !important",
    maxHeight:"105mm !important",
    minHeight:"105mm !important",
    borderRight:"0.1mm solid gray",
    borderTop:"0.2mm dotted black"
}

}));

// interface Props {
// 	qrData: qrDataInterface;
// }




export default function QrCodePanel(props) {
    const classes = useStyles();
    return (<div>
            <div style={{marginBottom: "-3mm"}}>
                <svg x="202mm" y="3.5mm" width="3mm" height="3mm" preserveAspectRatio="none"><svg fill="none" stroke="black" stroke-width="0.25mm" stroke-linecap="round" stroke-linejoin="round"><circle cx="2.25mm" cy="2.25mm" r="0.375mm"></circle><circle cx="2.25mm" cy="0.7500000000000001mm" r="0.375mm"></circle><line x1="1.9848349570550445mm" y1="1.9848349570550448mm" x2="1.5mm" y2="1.5mm"></line><line x1="1.125mm" y1="1.125mm" x2="0.5mm" y2="0.4999999999999998mm"></line><line x1="1.9848349570550448mm" y1="1.0151650429449552mm" x2="0.4999999999999998mm" y2="2.5mm"></line></svg></svg>
                </div>
            <div className={classes.qrMainBlock}>

                <div style={{width:"62mm",padding:"5mm",borderRight: '0.2mm dotted black'}}><Receipt qrData={props.qrData} /></div>
                    <div style={{marginLeft: "-1.7mm",marginTop: "89mm"}}>
                   <svg x="60.5mm" y="102mm" width="3mm" height="3mm" preserveAspectRatio="none">
                    <svg fill="none" stroke="black" stroke-width="0.25mm" stroke-linecap="round" stroke-linejoin="round"><circle cx="0.7499999999999999mm" cy="2.25mm" r="0.375mm"></circle><circle cx="2.25mm" cy="2.25mm" r="0.375mm"></circle><line x1="1.0151650429449552mm" y1="1.9848349570550448mm" x2="1.5mm" y2="1.5mm"></line><line x1="1.875mm" y1="1.125mm" x2="2.5mm" y2="0.5mm"></line><line x1="1.9848349570550448mm" y1="1.9848349570550448mm" x2="0.5mm" y2="0.4999999999999998mm"></line></svg></svg>
                    </div>
                    <div style={{ width:"148mm", padding:"5mm"}} >
                    <div style={{display:"flex"}}>
                    <div style={{width:"51mm"}}><PaymentPart qrData={props.qrData} /></div>
                    <div style={{width:"87mm"}}><PayableByInfo qrData={props.qrData}/></div>
                </div>
                </div>

            </div>
            </div>
    );
}
