/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
/* Material UI Styles */
import { makeStyles } from '@material-ui/core/styles';
/* Material UI Components */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { QrCodeVariantEnums } from '@qrtool-enums';
import { qrDataInterface } from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
// }


const VariantPanel = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onReferenceChange = (e, value) => {
        props.setQrData({
            ...props.qrData,
            reference: { ...props.qrData.reference, type: value.value }
        });
    }

    const QrCodeVariantOptions = QrCodeVariantEnums.map((opt) => {
        return {
            value: opt.value,
            label: t(opt.value),
        };
    });

    return (
        <Autocomplete
            disableClearable
            size="small"
            getOptionLabel={(option) => option.label}
            id="tags-outlined"
            // InputLabelProps={{ shrink: true }}
            // margin="normal"
            options={QrCodeVariantOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Variant"
                    placeholder="Select"
                    variant='standard'
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
            value={QrCodeVariantOptions.find(
                item => item.value === props.qrData.reference.type,
            )}
            onChange={onReferenceChange}
        />
    );
};



export default VariantPanel;
