var iso7064 = require('iso-7064');

const FORMAT_RF = /^RF[0-9]{2}[A-Z0-9]{1,21}$/;
const FORMAT_RF_BODY = /^[A-Z0-9]{1,21}$/;
const FORMAT_RF_HEAD = 'RF';

function stringifyInput(rawValue, valueName = 'rawValue') {
    if (rawValue !== null && rawValue !== undefined) {
        switch (typeof rawValue) {
            case 'string':
                return rawValue.toUpperCase().replace(/[^0-9A-Z]/g, '');
            default:
                throw new Error('Expecting ' + valueName + ' of type \'string\', found: \'' + (typeof rawValue) + '\'');
        }
    }

    throw new Error('Expecting ' + valueName + ' of type \'string\', found: \'' + rawValue + '\'');
}
export function generate(rawValue) {
    const value = stringifyInput(rawValue);

    if (!value.match(FORMAT_RF_BODY)) {
        throw new Error('Invalid Creditor Reference format; expecting: \'' + FORMAT_RF_BODY + '\', found: \'' + value + '\'');
    }

    return FORMAT_RF_HEAD + ('0' + (98 - iso7064.computeWithoutCheck(value + FORMAT_RF_HEAD + '00'))).slice(-2) + value;
}

export function validate(rawValue) {
    const value = stringifyInput(rawValue);

    if (!value.match(FORMAT_RF)) {
        throw new Error('Invalid Creditor Reference format; expecting: \'' + FORMAT_RF + '\', found: \'' + value + '\'');
    }

    return iso7064.computeWithoutCheck(value.substring(4, value.length) + value.substring(0, 4)) === 1;
}

export function calc(rawValue) {
    const value = stringifyInput(rawValue);
    if (!value.match(FORMAT_RF_BODY)) {
        throw new Error('Invalid Creditor Reference format; expecting: \'' + FORMAT_RF_BODY + '\', found: \'' + value + '\'');
    }
    return ('0' + (98 - iso7064.computeWithoutCheck(value + FORMAT_RF_HEAD + '00'))).slice(-2)
}
