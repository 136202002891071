/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
/* Material UI Styles */
import { makeStyles } from '@material-ui/core/styles';
/* Material UI Components */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { currencyEnumValueOnly, QrCodeVariantEnums } from '@qrtool-enums';
import { qrDataInterface } from '@qrtool-interfaces';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import QrReferenceDialog from './QrReferenceDialog';
import QrCreditorRefereneDialog from './QrCreditorRefereneDialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckIcon from '@material-ui/icons/Check';
import TextFieldNumberFormatCustom from '@qrtool-components/TextFieldNumberFormatCustom';
import { InoIBAN } from '@qrtool-utils/iban';
import { InoIbanSpecification } from '@qrtool-utils/ibanSpecification';
import VariantPanel from '../../VariantPanel/VariantPanel';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    cardArea: {
        padding: '0px 10px',
        fontWeight: 'normal',
        fontSize: '15px',
        userSelect: 'text',
    },
    divider: {
        width: '100%',
        height: '1px',
    },
    icons: {
        color: 'rgba(0,0,0,.54)'
    },
    alignSet: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    customTooltipPrimary: {
        color: 'white',
        backgroundColor: '#556cd6',
        fontSize: 14,
        maxWidth: 380
    },
    customTooltipGreen: {
        color: 'white',
        backgroundColor: '#4caf50 !important',
        fontSize: 14,
        maxWidth: 380
    },
    greenColorIcon: {
        color: '#4caf50 !important'
    },
    customTooltipError: {
        color: 'white',
        backgroundColor: '#ff5252',
        fontSize: 14
    },
    borderSet: {
        '& input:not([type=`range`])': {

            border: 'none !important'
        },
        '&.MuiInputBase-root': {
            '&.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                border: 'none !important'
            },
            border: 'none !important'
        }
    },
}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
// }
const inoIBAN = new InoIBAN();
const PaymentInformation = (props) => {
    const { t } = useTranslation();
    const [qrDataAccount, setQrDataAccount] = useState(props.qrData.account);
    const [ibanSpecification, setIbanSpecification] = useState(inoIBAN.getCountryStrucutre("CH"));
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const OnChangeQrdata = (key, value) => {
        props.setQrData({
            ...props.qrData,
            [`${key}`]: value
        })
    }

    const openDialog = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    const onCurrencyChange = (e, value) => {
        OnChangeQrdata('currency', value.value)
    }

    const OnChangeQrCheckesShowAmountByData = (e) => {
        props.setQrData({
            ...props.qrData,
            checks: { ...props.qrData.checks, showAmount: e.target.checked }
        })
    }
    const onchangeAmount = (e) => {
        OnChangeQrdata('amount', e.target.value)
    }
    const onChangeUnstructuredMessage = (e) => {
        OnChangeQrdata('unstructeredMessage', e.target.value)
    }
    const renderError = () => {
        return _isValid();

    }


    const _onChangeAccountNumber = (e) => {
        let iban = e.target.value.replace(/\s+/g, '');
        setIbanSpecification(inoIBAN.getCountryStrucutre(iban))
        console.log(ibanSpecification.isValid(iban));
        if (iban.length <= ibanSpecification.length) {
            OnChangeQrdata('account', iban)
        }
        setQrDataAccount(iban);
    }
    const _isValid = () => {
        if (props.qrData.reference.type.toUpperCase() == "QRR") {
            return ibanSpecification.isValid(qrDataAccount) && ["CH", "LI"].indexOf(ibanSpecification.countryCode.toLocaleUpperCase()) !== -1
        }
        return ibanSpecification.isValid(qrDataAccount)

    }
    const _errorMessage = () => {
        if (qrDataAccount.length !== ibanSpecification.length) {
            return "Length";
        }
        if (props.qrData.reference.type.toUpperCase() == "QRR") {
            if (!_isValid()) {
                return t('qrrIBANErrorMessage');
            }
        } else {
            if (!_isValid()) {
                return t('IBANErrorMessage');
            }
        }
        return t('IBANvalidMessage')

    }
    const _warningMessage = () => {
        if (props.qrData.reference.type.toUpperCase() == "QRR") {
            return t('qrIBANInfoTextOfPemitted');
        } else {
            return t('qrIBANInfoTextOflength', {
                countryCode: ibanSpecification.countryCode,
                length: ibanSpecification.length
            })
        }
    }


    const currencyOptions = currencyEnumValueOnly.map((opt) => {
        return {
            value: opt.value,
            label: t(opt.value),
        };
    });


    return (
        <Card style={{ minHeight: 596 }}>
            <div className={classes.cardArea}>
                <Grid container>
                    <Grid
                        item
                        sm={8}
                        xs={8}
                        style={{ padding: '10px 0px' }}
                    >
                        <Typography component="h6" variant="h6" style={{ float: 'left' }}>
                            {t('paymentInfomation')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={3}
                        xs={3}
                        className={classes.alignSet}
                        style={{ padding: 10 }}
                    >
                        <Tooltip title={t('paymentInfomationHeaderInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>
                    </Grid>
                    <Grid
                        item
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                        style={{ padding: 10 }}
                    >
                        <PersonIcon
                            className={classes.icons}
                        />
                    </Grid>
                </Grid>
                <Divider
                    className={classes.divider}
                    style={{ marginBottom: '5px' }}
                />
                <Grid container spacing={1}>


                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <VariantPanel
                            qrData={props.qrData}
                            setQrData={props.setQrData}
                        />

                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={<div>{t('qrVarantInfoText1')}<br/>{t('qrVarantInfoText2')}<br/>{t('qrVarantInfoText3')}<br/>{t('qrVarantInfoText4')}</div>}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>

                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <TextField
                            fullWidth
                            id="payable-to_IBAN"
                            autoComplete="new-password"
                            label={props.qrData.reference.type === 'QRR' || props.qrData.reference.type === 'NON' ? t('IBAN') + "*" : t('QR-IBAN') + '*'}
                            variant={"standard"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable-to_IBAN",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={<div>
                                <span style={{ float: 'right' }}> {qrDataAccount?.length + '/ ' + ibanSpecification?.length}</span>
                            </div>}
                            value={qrDataAccount}
                            // error={!_isValid()}
                            error={qrDataAccount.length === 0 ? false : !_isValid()}
                            onChange={_onChangeAccountNumber}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={_errorMessage()}
                                            aria-label="info"
                                            placement="top"
                                            classes={{
                                                tooltip: _isValid() ? classes.customTooltipGreen : classes.customTooltipError,
                                            }}
                                        >
                                            {qrDataAccount.length !== 0 ? (_isValid()
                                                ? <CheckIcon color="primary" className={classes.greenColorIcon} />
                                                : <ReportProblemIcon color="error" />) : <div></div>
                                            }
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip
                            title={_warningMessage()}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>

                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <Autocomplete
                            disableClearable
                            size="small"
                            // className={classes.borderSet}
                            getOptionLabel={(option) => option.label}
                            id="tags-outlined"
                            // InputLabelProps={{ shrink: true }}
                            // margin="normal"
                            options={currencyOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('currency') + "*"}
                                    placeholder="Select"
                                    variant='standard'
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.borderSet}
                                    InputProps={{
                                        ...params.InputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                            value={currencyOptions.find(
                                item => item.value === props.qrData.currency,
                            )}
                            onChange={onCurrencyChange}
                        />

                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('currencyInfoext')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>

                    <Grid
                        item
                        lg={2}
                        sm={2}
                        xs={2}
                    >
                        <div style={{ marginTop: 10 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            props.qrData.checks.showAmount
                                        }
                                        onChange={OnChangeQrCheckesShowAmountByData}
                                    />
                                }
                                label=""
                            />

                        </div>
                    </Grid>
                    <Grid
                        item
                        lg={9}
                        sm={9}
                        xs={9}
                    >
                        <TextField
                            fullWidth
                            id="Amount"
                            label={t('amount') + "*"}
                            autoComplete="new-password"
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "Amount",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="numberformat"
                            InputProps={{
                                inputComponent: TextFieldNumberFormatCustom,
                            }}
                            disabled={!props.qrData.checks.showAmount}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.amount}
                            // error={titleValue?.length > 32}
                            onChange={onchangeAmount}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        {!!props.qrData.checks.showAmount ? <Tooltip title={t('amountInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>
                            :
                            <InfoOutlinedIcon className={classes.icons} />}

                    </Grid>
                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <TextField
                            fullWidth
                            id="unstructure_message"
                            label={t('unstructuredMsg')}
                            autoComplete="new-password"
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "unstructure_message",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.unstructeredMessage}
                            // error={titleValue?.length > 32}
                            onChange={onChangeUnstructuredMessage}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('unStructuredMassageInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>




                    {props.qrData.reference.type !== 'NON' && <>
                        <Grid
                            item
                            lg={9}
                            sm={9}
                            xs={9}
                        >
                            <TextField
                                fullWidth
                                id="qr_reference"
                                label={(props.qrData.reference.type === 'SCOR') ? t('QR-reference') + "*" : t('CreditorReference') + "*"}
                                // variant="outlined"
                                color="primary"
                                autoComplete="new-password"
                                inputProps={{
                                    'aria-label': "qr_reference",
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                                value={props.qrData.reference.reference}
                            />
                        </Grid>

                        <Grid
                            item
                            lg={2}
                            sm={2}
                            xs={2}
                            style={{textAlign:'center'}}
                        >
                            <div style={{ marginTop: 10 }}>
                                {/* <Button
                                    onClick={openDialog}
                                    variant="contained"
                                    color='primary'
                                > */}
                                <IconButton edge="end" onClick={openDialog} style={{padding:0}}>
                                    <EditIcon />
                                </IconButton>
                                {/* </Button> */}
                            </div>
                        </Grid>
                        <Grid
                            item
                            lg={1}
                            sm={1}
                            xs={1}
                            className={classes.alignSet}
                        >
                            <Tooltip title={t('referenceTypeInfoText')}
                                aria-label="info"
                                placement="top"
                                classes={{
                                    tooltip: classes.customTooltipPrimary,
                                }}
                            >
                                <InfoOutlinedIcon className={classes.icons} />
                            </Tooltip>

                        </Grid>

                    </>
                    }
                    {open && props.qrData.reference.type === 'SCOR' && <QrReferenceDialog
                        open={open}
                        onClose={closeModal}
                        qrData={props.qrData}
                        setQrData={props.setQrData}
                    />}
                    {open && props.qrData.reference.type === 'QRR' && <QrCreditorRefereneDialog
                        open={open}
                        onClose={closeModal}
                        qrData={props.qrData}
                        setQrData={props.setQrData}
                    />}

                </Grid>
            </div>
        </Card >
    );
};



export default PaymentInformation;
