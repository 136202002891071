import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { qrDataInterface  } from '@qrtool-interfaces';
import QrReceiptSvg  from './QrReceiptSvg';


const useStyles = makeStyles(() => ({
qrMainBlock:{
    display:"flex",
    width: '210mm',
    borderRight:"0.1mm solid gray",
    borderTop:"0.2mm dotted black"
}

}));

// interface Props {
// 	qrData: qrDataInterface;
// }




export default function QrReceiptPanel(props) {
    const classes = useStyles();
    return (
            <div className={classes.qrMainBlock}>
					<QrReceiptSvg qrData={props.qrData}/>
            </div>
    );
}
