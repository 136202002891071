import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CurrencyAmount } from "./Components";
import { qrDataInterface } from '@qrtool-interfaces';
import { QRCode } from "react-qr-svg";
import { QrCodeVariantEnums } from '@qrtool-enums';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	title: {
		height: "7mm",
		fontSize: "11pt",
		fontWeight: "bold",
		textAlign: "left"
	},
	qrImageBlock: {
		height: "56mm",
		padding: "5mm 5mm 5mm 0"
	},
	CurrencyAmountBlock: {
		height: "22mm !important"
	}
}));
// interface Props {
// 	qrData: qrDataInterface;
// }



const PaymentPart = (props) => {

	const classes = useStyles();
	const { t } = useTranslation();
	const getQrCodeValue = () => {
		return `SPC 0200 1
	${props.qrData.account}
	S
	${props.qrData.creditor.name}
	${props.qrData.creditor.street}
	${props.qrData.creditor.buildingNumber}
	${props.qrData.creditor.postalCode}
	${props.qrData.creditor.city}
	${props.qrData.creditor.country}
	${props.qrData.currency}
	${props.qrData.amount}
	S
	${props.qrData.debtor.name}
	${props.qrData.debtor.street}
	${props.qrData.debtor.buildingNumber}
	${props.qrData.debtor.postalCode}
	${props.qrData.debtor.city}
	${props.qrData.debtor.country}
	${props.qrData.reference.type}
	${props.qrData.reference.reference}
	${props.qrData.unstructeredMessage}
	`;
	}
	return (
		<div>
			<div className={classes.title}>
				{t('paymentPart')}
			</div>
			<div className={classes.qrImageBlock}>
				<div style={{ height: "46mm" }}>
					<QRCode
						bgColor="#FFFFFF"
						fgColor="#000000"
						level="M"
						value={getQrCodeValue()}
					/>
				</div>
			</div>
			<div className={classes.CurrencyAmountBlock}>
				<CurrencyAmount qrData={props.qrData} />
			</div>

		</div>
	);
};



export default PaymentPart;
