export default {
    type:"",
    account:"",
    amount:"",
    currency: "",
    creditor: {
        type: "",
        name: "",
        street: "",
        postalCode: "",
        buildingNumber:"",
        city: "",
        country: "",
	},
	reference : {
		value:"",
		besr: "",
		checkdigit :"",
		reference : "",
		type :"",
		},
    debtor: {
        type: "",
        name: "",
        street: "",
        postalCode: "",
        buildingNumber:"",
        city: "",
        country: "",
    },
    unstructeredMessage: "",
    billInformation: "",
    language: "",
    checks: {
        showPaybleBy:true,
        showAmount:true,
    }
}


