import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CurrencyAmount, AccountPayableTo, PayableBy, ReferenceCode } from "./Components";
import { qrDataInterface } from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  heading: {
    height: "7mm",
    fontSize: "11pt",
    fontWeight: "bold",
    textAlign: "left"
  },
  acceptanceSection: {
    height: "18mm",
    fontSize: "6pt",
    lineHeight: "8pt",
    fontWeight: "bold",
    textAlign: "right"
  }
}));

// interface Props {
//   qrData: qrDataInterface;
// }

const Receipt = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.heading}>
        {t('receipt')}
      </div>
      <div style={{ height: "56mm" }}>
        <div style={{ marginBottom: "9pt" }}><AccountPayableTo qrData={props.qrData} /></div>
        {props.qrData.reference.type === 'NON' ? "" :
          <div style={{ marginBottom: "9pt" }}><ReferenceCode qrData={props.qrData} /></div>}
        <div style={{ marginBottom: "9pt" }}><PayableBy qrData={props.qrData} /></div>

      </div>

      <div style={{ height: "14mm" }}>
        <CurrencyAmount qrData={props.qrData} />
      </div>
      <div className={classes.acceptanceSection} >
        {t('acceptancePoint')}
      </div>

    </div>
  );
};



export default Receipt;
