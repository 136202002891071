import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {qrDataInterface} from '@qrtool-interfaces';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  currencyAmountBlock:{
    display:"flex",
    textAlign:"left"
  },
  heading:{
    fontSize:"6pt",
    lineHeight:"9pt",
    fontWeight:"bold"
  },
  text:{
    fontSize:"8pt",
    lineHeight:"11pt"
  }
}));

// interface Props {
//   qrData: qrDataInterface;
// }

const CurrencyAmount = (props ) => {
const classes = useStyles();
const { t } = useTranslation();
  return (
   <div className={classes.currencyAmountBlock}>
     <div style={{marginRight:"5mm"}}>
         <div className={classes.heading}>{t('currency')}</div>
         <div className={classes.text}>{props.qrData.currency}</div>
    </div>
    <div >
        <div className={classes.heading}>{t('amount')}</div>
        <div className={classes.text}>
        {props.qrData.checks["showAmount"]=== true ? Number(props.qrData.amount).toLocaleString() :
        <svg x="9.7mm" y="66.7mm" width="30.6mm" height="6.6mm"><rect x="0.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="0.3mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="0.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="0.3mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect><rect x="26.3mm" y="6.035417mm" width="2mm" height="0.2645833333333333mm" fill="black" stroke="none"></rect><rect x="28.035417mm" y="4.3mm" width="0.2645833333333333mm" height="2mm" fill="black" stroke="none"></rect></svg>
        }
        </div>
    </div>

   </div>
  );
};



export default CurrencyAmount;
