/* eslint-disable no-use-before-define */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const useStyles = makeStyles((theme) => ({
  input: {
    border: 'none !important'
  },

}));
export default function PDFInvoiceSelector() {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        disableClearable
        size="small"
        getOptionLabel={(option) => option.title}
        id="tags-outlined"
        // InputLabelProps={{ shrink: true }}

        // margin="normal"
        options={top100Films}
        renderInput={(params) => (
          <TextField
            {...params}

            label="Bank account"
            placeholder="Select"
            variant="standard"
            style={{ width: '100%', border: 'none ' }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              // classes: {input: classes.input},
              // endAdornment: (
              //     <InputAdornment position="start">
              //       <ArrowDropDownIcon     />
              //     </InputAdornment>
              //   ),
            }}
          />
        )}
      // value={}
      />
    </div>
  );
}


const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
]
