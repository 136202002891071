/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
/* Material UI Styles */
import { makeStyles } from '@material-ui/core/styles';
/* Material UI Components */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles((theme) => ({
    // autocompleteStyle: autocompleteStyle,
    borderSet: {
        '& input:not([type=`range`])': {

            border: 'none !important'
        },
        '&.MuiInputBase-root': {
            '&.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                border: 'none !important'
            },
            border: 'none !important'
        }
    },
    heading: {
        textAlign: 'left',
        marginTop: 10
    }
}));

const AccountPart = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState(props.open || false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const qrTypeOptions = [
        {
            label: 'QR With IBAN-SCOR',
            value: 'qriban',
        },
        {
            label: 'QR Without IBAN-SCOR',
            value: 'iban',
        },
    ];

    return (
        <Grid container spacing={1}>
            <Grid item lg={12} sm={12} style={{ marginBottom: '4px' }} xs={12}>
                <div className={classes.heading}>Payment swiss QR code</div>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                {/* {bankaccountOptions && ( */}
                <Autocomplete
                    disableClearable
                    // disabled={_invoice?.status !== invoiceStatusEnumIndexedObj['DRAFT']}
                    size="small"
                    // className={classes.autocompleteStyle}
                    getOptionLabel={(option) => option.label}
                    id="tags-outlined"
                    // InputLabelProps={{ shrink: true }}
                    // margin="normal"
                    // disabled={true}
                    // options={bankaccountOptions}
                    options={[]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Bank account"
                            placeholder="Select"
                            variant='standard'
                            style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                // value={bankaccountOptions?.find(
                //   item =>
                //     item.value.accountno === _invoice?.creditor_accountno ||
                //     item.value.qriban === _invoice?.creditor_accountno,
                // )}
                />
                {/* )} */}
            </Grid>
            <Grid item lg={12} sm={12} style={{ marginTop: 7 }} xs={12}>
                <Autocomplete
                    disableClearable
                    //   disabled={_invoice?.status !== invoiceStatusEnumIndexedObj['DRAFT']}
                    size="small"
                    // className={classes.autocompleteStyle}
                    className={classes.borderSet}
                    getOptionLabel={(option) => option.label}
                    id="tags-outlined"
                    // InputLabelProps={{ shrink: true }}
                    // margin="normal"
                    //   disabled={true}
                    //   options={InvoiceQRCodeTypeEnum}
                    options={[]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="QR type"
                            placeholder="Select"
                            variant='standard'
                            style={{ width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.borderSet}
                        />
                    )}
                // value={InvoiceQRCodeTypeEnum.find(
                //     item => item.value === _invoice?.qr_code_type,
                // )}
                />
            </Grid>

            <Grid item lg={12} sm={12} style={{ marginTop: 7 }} xs={12}>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Account number"
                    required
                    // value={_invoice?.creditor_accountno}
                    variant="standard"
                />
            </Grid>
            <Grid item lg={4} sm={12} style={{ marginTop: 7 }} xs={12}>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Amount"
                    required
                    // value={
                    //     _invoice?.invoice_client_currency === _invoice?.invoice_org_currency
                    //         ? props.grandTotal
                    //         : `${(
                    //             parseFloat(props.grandTotal) *
                    //             parseFloat(_invoice?.currency_conversion_rate)
                    //         ).toFixed(2)}`
                    // }
                    variant="standard"
                />
            </Grid>
            <Grid item lg={4} sm={12} style={{ marginTop: 7 }} xs={12}>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Currency"
                    required
                    // value={_invoice?.invoice_client_currency}
                    variant="standard"
                />
            </Grid>

            <Grid item lg={4} sm={12} style={{ marginTop: 7 }} xs={12}>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Due date"
                    required
                    // value={new Date(_invoice?.invoice_due_date).toLocaleDateString()}
                    variant="standard"
                />
            </Grid>
            <Grid item lg={12} sm={12} style={{ marginTop: 7 }} xs={12}>
                <TextField
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Reference"
                    required
                // value={_invoice?.reference}
                // variant="standard"
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <div style={{ marginLeft: 4 }}>{'Additional information'}</div>
                {/* <ContentEditbale
                    editortype="textarea"
                    inputHeight="45px"
                    inputWidth="92%"
                    // isDisabled={_invoice?.status !== invoiceStatusEnumIndexedObj['DRAFT']}
                    // onFocusOut={additional_info => {
                    //     if (additional_info !== _invoice.additional_info) {
                    //         _invoice.additional_info = additional_info;
                    //         props.updateInvoiceMetaData(_invoice);
                    //     }
                    // }}
                    //   inputPlaceHolder="Additional information"
                    style={{
                        border: '2px solid #3f51b',
                    }}
                // text={_invoice?.additional_info}
                // validate={text => {
                //     return true;
                // }}
                /> */}

            </Grid>
        </Grid>
    );
};



export default AccountPart;
