export const QrCodeVariantEnums = [
    {
        label: 'QR-IBAN with QR reference (formaly ESR reference) (SCOR)',
		value: 'SCOR',
    },
    {
        label: 'IBAN with creditor reference (ISO 11649) (QRR)',
		value: 'QRR',
    },
    {
        label: 'IBAN without reference (NON)',
		value: 'NON',
    }

];


export const currencyEnumValueOnly = [
    { value: 'CHF', label: 'CHF' },
    { value: 'EUR', label: 'EUR' },
    { value: 'USD', label: 'USD' },
  ];
