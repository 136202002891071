/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
/* Material UI Styles */
import { makeStyles } from '@material-ui/core/styles';
/* Material UI Components */
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import MoneyIcon from '@material-ui/icons/Money';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CountrySelector from '@qrtool-components/CountrySelector/CountrySelector';
import { qrDataInterface } from '@qrtool-interfaces';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    cardArea: {
        padding: '20px 10px',
        fontWeight: 'normal',
        fontSize: '15px',
        userSelect: 'text',
    },
    headingAlign: {
        display: 'flex',
        alignItems: 'center',
        float: 'left',
    },
    divider: {
        width: '100%',
        height: '1px',
    },
    icons: {
        color: 'rgba(0,0,0,.54)'
    },
    alignSet: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    customTooltipPrimary: {
        color: 'white',
        backgroundColor: '#556cd6',
        fontSize: 14,
        maxWidth: 380
    },
    checkBoxAlign: {
        '&.MuiCheckbox-root': {
            padding: '4px !important'
        }
    },
    checkBoxFormControllAlign: {
        '&.MuiFormControlLabel-root': {
            marginLeft: '-6px',
            marginRight: '0px'
        }
    }
}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
// }


const PayableBy = (props) => {

    const classes = useStyles();

    const { t } = useTranslation();
    const OnChangeQrdata = (key, value) => {
        props.setQrData({
            ...props.qrData,
            [`${key}`]: value
        })
    }

    const OnChangeQrDebtorData = (key, value) => {
        props.setQrData({
            ...props.qrData,
            debtor: { ...props.qrData.debtor, [`${key}`]: value }
        })
    }

    const OnChangeQrCheckesShowPaybleByData = (e) => {
        props.setQrData({
            ...props.qrData,
            checks: { ...props.qrData.checks, showPaybleBy: e.target.checked }
        })
    }


    const OnChangeDebtorName = (e) => {
        OnChangeQrDebtorData('name', e.target.value)
    }

    const OnChangeDebtorStreet = (e) => {
        OnChangeQrDebtorData('street', e.target.value)
    }

    const OnChangeDebtorBuildingNumber = (e) => {
        OnChangeQrDebtorData('buildingNumber', e.target.value)
    }
    const OnChangeDebtorPostalCode = (e) => {
        OnChangeQrDebtorData('postalCode', e.target.value)
    }
    const OnChangeDebtorTown = (e) => {
        OnChangeQrDebtorData('city', e.target.value)
    }
    const OnChangeDebtorCountry = (value) => {
        OnChangeQrDebtorData('country', value)
    }





    return (
        // <Card style={{ minHeight: 480 }}>
            <div className={classes.cardArea}>
                <Grid container>
                    <Grid
                        item
                        sm={10}
                        xs={10}
                        style={{ padding: '10px 0px' }}
                    >
                        <div className={classes.headingAlign}>
                            <FormControlLabel
                                className={classes.checkBoxFormControllAlign}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            props.qrData.checks.showPaybleBy
                                        }
                                        className={classes.checkBoxAlign}
                                        onChange={OnChangeQrCheckesShowPaybleByData}
                                    />
                                }
                                label=""
                            />
                            <Typography component="h6" variant="h6">
                                {t('payableBy')}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid
                        item
                        sm={2}
                        xs={2}
                        className={classes.alignSet}
                        style={{ padding: 10 }}
                    >
                        <PersonIcon
                            className={classes.icons}
                        />
                        <ArrowForwardIcon
                            className={classes.icons}
                        />
                        <MoneyIcon
                            className={classes.icons}
                        />
                    </Grid>
                </Grid>
                <Divider
                    className={classes.divider}
                    style={{ marginBottom: '5px' }}
                />
                {props.qrData.checks.showPaybleBy && <Grid container spacing={1}>
                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <TextField
                            fullWidth
                            id="payable_by_name"
                            label={t('Name') + "*"}
                            autoComplete="new-password"
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable_by_name",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={props.qrData.debtor.name}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeDebtorName}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorNameInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            id="payable_by_street"
                            autoComplete="new-password"
                            label={t('Street')}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable_by_street",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.debtor.street}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeDebtorStreet}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorStreetInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xs={4}
                    >
                        <TextField
                            fullWidth
                            id="payable_by_number"
                            autoComplete="new-password"
                            label={t("Number")}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable_by_number",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.debtor.buildingNumber}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeDebtorBuildingNumber}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorNumberInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={4}
                        sm={4}
                        xs={4}
                    >
                        <TextField
                            fullWidth
                            id="payable_by_postal_code"
                            autoComplete="new-password"
                            label={t('postalCode') + "*"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable_by_postal_code",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.debtor.postalCode}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeDebtorPostalCode}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorPostalCodeInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            id="payable_by_town"
                            autoComplete="new-password"
                            label={t('town') + "*"}
                            // variant="outlined"
                            color="primary"
                            inputProps={{
                                'aria-label': "payable_by_town",
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // helperText={titleValue?.length > 32 && 'Auccepts only 32 character'}
                            value={props.qrData.debtor.city}
                            // error={titleValue?.length > 32}
                            onChange={OnChangeDebtorTown}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorTownInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                    <Grid
                        item
                        lg={11}
                        sm={11}
                        xs={11}
                    >
                        <CountrySelector
                            recent={['CH']}
                            value={props.qrData.debtor.country}
                            handleChangeCountry={OnChangeDebtorCountry}
                            label={t('country') + '*'}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={1}
                        sm={1}
                        xs={1}
                        className={classes.alignSet}
                    >
                        <Tooltip title={t('debtorCountryInfoText')}
                            aria-label="info"
                            placement="top"
                            classes={{
                                tooltip: classes.customTooltipPrimary,
                            }}
                        >
                            <InfoOutlinedIcon className={classes.icons} />
                        </Tooltip>

                    </Grid>
                </Grid>}
            </div>
        // </Card>
    );
};



export default PayableBy;
