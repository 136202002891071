import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { DownloadExportPanel, PDFInvoiceSelector } from "./Components/DownloadExportPanel"
import QrCodePanel from './Components/QrCodePanel/QrCodePanel';
import InputDataPanel from './Components/InputDataPanel/InputDataPanel';
import QrReceiptPanel from './Components/QrReceiptPanel/QrReceiptPanel';

import VariantPanel from './Components/VariantPanel/VariantPanel';
// import { qrDataInterface } from '@qrtool-interfaces';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useTranslation,Trans } from "react-i18next";
// import i18n from "i18next";
// import '../../css/style.css';
// require('../../css/style.css')


import i18n from '../i18n';
import { withMobileDialog } from '@material-ui/core';


const useStyles = makeStyles(() => ({
    gridroot: {
        margin: 20
    },
    gridContaner: {
        border: '1px solid #eeee',
        minHeight: 401
    },
    textTransformSet: {
        textTransform: 'uppercase',
        fontSize: '15px'
    },
    gridContanerVariantPanel: {
        border: '1px solid #eeee',
        minHeight: 73
    },
    '@global': {
        '.MuiInput-root > input': {
            border: 'none !important',
            outline: 'none !important',
        },
        '@media all and (max-width: 930.98px) and (min-width: 300px) ':{
            '.MuiGrid-grid-xs-6': {
                flexGrow: 0,
                maxWidth: '100%',
                flexBasis: '100%',
            },
        },
        '@media (min-width: 931px) ':{
            '.MuiGrid-grid-xs-6': {
                flexGrow: 0,
                maxWidth: '50%',
                flexBasis: '50%',
            },
        },
        '.MuiOutlinedInput-multiline':{
            padding: '6px 0 7px !important'
        }
    },
    container :{
        width: '100%',
        paddingRight: 15,
        paddingLeft: 15,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '3em',
        maxWidth: '90%',
    },
}));




export default function App(props) {
    const classes = useStyles();


    const [qrData, setQrData] = useState({
        'type': "SCOR", //   : default QRR
        'account': "",
        'amount': 0,
        'currency': 'CHF',
        'creditor': {
            'type': "S",
            'name': '',
            'street': '',
            'buildingNumber': '',
            'postalCode': '',
            'city': '',
            'country': '',
        },
        'reference': {
            'value': '',
            'besr': '',
            'checkdigit': 0,
            'reference': '',
            'type': 'SCOR',
        },
        'debtor': {
            'type': "S",
            'name': '',
            'street': '',
            'buildingNumber': '',
            'postalCode': '',
            'city': '',
            'country': '',
        },
        'unstructeredMessage': '',
        'billInformation': '',
        'language': "en",
        'checks': {
            'showPaybleBy': true,
            'showAmount': true
        }
    });
    const { t } = useTranslation();
    const [_qrCodeReferenceObj, _setQrCodeReferenceObj] = useState({ value: 'SCOR' });

    const [activeLang, setActiveLang] = useState('EN');
    let languages = [
        'EN', 'DE'
        // , 'FR', 'IT'
    ]


    const handleActiveLangChange = (e, value) => {
        setActiveLang(value);
        i18n.changeLanguage(value.toLowerCase());
    }
    return (

        <Container maxWidth={false} className={classes.container} >
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item lg={12} xs={12} xl={12}>
                        <div style={{ float: 'right'}}>
                         <ButtonGroup
                                variant="text"
                                // color="primary"
                                aria-label="text primary button group">
                                {languages.map((item, index) =>
                                    <Button
                                        key={index}
                                        style={{backgroundColor:'#ededed'}}
                                        onClick={(e) => handleActiveLangChange(e, item)}
                                        color={activeLang === item? 'primary': 'secondary' }
                                    >
                                        {item}
                                    </Button>

                                )}

                            </ButtonGroup> 
                            
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12} xl={12}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="h4" component="h1">
                                <Trans i18nKey="pageHeaderLine1"/></Typography>
                            <Typography variant="h6" component="h1">
                                {/* {t('pageHeaderLine2')} */} <Trans i18nKey="pageHeaderLine2"/>
                            </Typography>

                            <Typography variant="h6" component="h1" className={classes.textTransformSet}><Trans i18nKey="pageHeaderLine3"/></Typography>
                        </div>
                    </Grid>
                    
                </Grid>
                {/* <div className={classes.gridroot}> */}
                    <Grid container spacing={1}>
                        {/* <Grid item lg={12} xs={12} xl={12}>
                            <Paper className={classes.gridContanerVariantPanel}>
                            <div style={{ padding: 10 }}>
                                <VariantPanel
                                    qrData={qrData}
                                    setQrData={setQrData}
                                />
                                </div>
                            </Paper>
                        </Grid> */}
                        <Grid item lg={12} xs={12} xl={12}>
                            <InputDataPanel
                                qrData={qrData}
                                setQrData={setQrData}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12} xl={12} style={{ marginTop: 10 }}>
                            <Paper style={{ minHeight: 50 }}>
                                <DownloadExportPanel qrData={qrData} />
                            </Paper>
                        </Grid>
                        <Grid item lg={12} xs={12} xl={12} style={{ marginTop: 10 }}>
                            <Paper className={classes.gridContaner} style={{ overflowX: "scroll" }}>
                                <QrReceiptPanel qrData={qrData} />
                            </Paper>
                        </Grid>
                        
                    </Grid>
                {/* </div> */}
            </React.Fragment>
        </Container >
    );
}
