export function InoIbanSpecification(countryCode, length, structure, example) {
  this.countryCode = countryCode;
  this.length = length;
  this.structure = structure;
  this.example = example;

  // private _cachedRegex='';

  /**
   * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
   * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
   *
   * @param iban the IBAN
   * @returns the prepared IBAN
   */

  this.iso13616Prepare = function (iban) {
    const A = 'A'.charCodeAt(0);
    const Z = 'Z'.charCodeAt(0);

    iban = iban.toUpperCase();
    iban = iban.substr(4) + iban.substr(0, 4);

    return iban
      .split('')
      .map((n) => {
        const code = n.charCodeAt(0);
        if (code >= A && code <= Z) {
          // A = 10, B = 11, ... Z = 35
          return code - A + 10;
        } else {
          return n;
        }
      })
      .join('');
  }


  /**
   * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
   */
  this.iso7064Mod97_10 = function (iban) {
    let remainder = iban,
      block;

    while (remainder.length > 2) {
      block = remainder.slice(0, 9);
      remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
    }

    return parseInt(remainder, 10) % 97;
  }

  /**
   * Parse the BBAN structure used to configure each IBAN Specification and returns a matching regular expression.
   * A structure is composed of blocks of 3 characters (one letter and 2 digits). Each block represents
   * a logical group in the typical representation of the BBAN. For each group, the letter indicates which characters
   * are allowed in this group and the following 2-digits number tells the length of the group.
   */
    this.parseStructure = function (structure) {
    // split in blocks of 3 chars
    const regex = structure.match(/(.{3})/g).map((block) => {
      // parse each structure block (1-char + 2-digits)
      let format;
      const pattern = block.slice(0, 1);
      const repeats = parseInt(block.slice(1), 10);

      switch (pattern) {
        case 'A':
          format = '0-9A-Za-z';
          break;
        case 'B':
          format = '0-9A-Z';
          break;
        case 'C':
          format = 'A-Za-z';
          break;
        case 'F':
          format = '0-9';
          break;
        case 'L':
          format = 'a-z';
          break;
        case 'U':
          format = 'A-Z';
          break;
        case 'W':
          format = '0-9a-z';
          break;
      }

      return '([' + format + ']{' + repeats + '})';
    });

    return new RegExp('^' + regex.join('') + '$');
  }

  /**
   * Lazy-loaded regex (parse the structure and construct the regular expression the first time we need it for validation)
   */
   this._regex = function () {
    return this._cachedRegex || (this._cachedRegex = this.parseStructure(this.structure));
  }

  /**
   * Check if the passed iban is valid according to this specification.
   *
   * @param iban the iban to validate
   * @returns true if valid, false otherwise
   */
    this.isValid = function (iban) {
    return (
      this.length === iban.length &&
      this.countryCode === iban.slice(0, 2) &&
      this._regex().test(iban.slice(4)) &&
      this.iso7064Mod97_10(this.iso13616Prepare(iban)) === 1
    );
  }

  /**
   * Check if the passed iban is a qr iban
   *
   * @param iban the iban to check
   * @returns true if it is a qr iban, false otherwise
   */
   this.isQRIBAN = function (iban) {
    const iid = Number(iban.slice(4, 9));
    return iid >= 30000 && iid <= 31999;
  }

  /**
   * Convert the passed IBAN to a country-specific BBAN.
   *
   * @param iban the IBAN to convert
   * @param separator the separator to use between BBAN blocks
   * @returns the BBAN
   */
    this.toBBAN = function (iban, separator) {
    return this._regex().exec(iban.slice(4)).slice(1).join(separator);
  }

  /**
   * Convert the passed BBAN to an IBAN for this country specification.
   * Please note that <i>"generation of the IBAN shall be the exclusive responsibility of the bank/branch servicing the account"</i>.
   * This method implements the preferred algorithm described in http://en.wikipedia.org/wiki/International_Bank_Account_Number#Generating_IBAN_check_digits
   *
   * @param bban the BBAN to convert to IBAN
   * @returns the IBAN
   */
    this.fromBBAN = function (bban) {
    if (!this.isValidBBAN(bban)) {
      throw new Error('Invalid BBAN');
    }

    const remainder = this.iso7064Mod97_10(this.iso13616Prepare(this.countryCode + '00' + bban)),
      checkDigit = ('0' + (98 - remainder)).slice(-2);

    return this.countryCode + checkDigit + bban;
  }

  /**
   * Check of the passed BBAN is valid.
   * This function only checks the format of the BBAN (length and matching the letter/number specs) but does not
   * verify the check digit.
   *
   * @param bban the BBAN to validate
   * @returns true if the passed bban is a valid BBAN according to this specification, false otherwise
   */
   this.isValidBBAN = function (bban) {

    return this.length - 4 === bban.length && this._regex().test(bban);
  }
}