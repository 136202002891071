import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { qrDataInterface } from '@qrtool-interfaces';
import { InoMod10 } from '@qrtool-utils/mod10';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    addButton: {
        minWidth: 91
    },
    title: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important',
        fontWeight: 500,
        fontSize: '1.5rem !important'
    },
    qrInfoText: {
        color: 'rgba(0,0,0,.6)'
    },
    showDigit: {
        fontSize: '.875rem',
        fontWeight: 400,
        lineHeight: '1.375rem',
        letterSpacing: '.0071428571em',
        color: 'rgba(0,0,0,.6)',
        padding: '0 24px 20px'
    }
}));

// interface Props {
//     qrData: qrDataInterface;
//     setQrData: Function;
//     open,
//     onClose: Function
// }


const QrReferenceDialog = (props) => {
    const classes = useStyles();
    const { open } = props;
    const [referenceObj, setReferenceObj] = useState(props.qrData.reference);
    const closeModal = () => {
        props.onClose && props.onClose();
    };

    const OnChangeQrReferenceObj = (key, value) => {
        setReferenceObj(
            {
                ...referenceObj,
                [`${key}`]: value
            }
        )

    }



    const onChangeValue = (e) => {
        let checkDigit = InoMod10.calc(e.target.value);
        setReferenceObj(
            {
                ...referenceObj,
                'checkdigit': checkDigit,
                'value': e.target.value,
                'reference': `${referenceObj.besr.toString().substring(0, 2)} ${((referenceObj.besr.toString().substring(2, (referenceObj.besr.length - 1)) + e.target.value + checkDigit)?.toString().replace(/[^\dA-Z]/g, '').replace(/(.{5})/g, '$1 ').trim())}`
            });
    }

    const onChangeBserId = (e) => {
        OnChangeQrReferenceObj('besr', e.target.value)
    }


    const onClickInsert = (e) => {
        props.setQrData({
            ...props.qrData,
            reference: referenceObj
        })
        closeModal();
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="qr_reference_dialog"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="qr_reference_dialog">
                        {`QR Reference`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <div className={classes.qrInfoText}>
                                    Some financial institutions recommend using the BESR-ID in the first six digits of the<br />
                                    QR reference during the parallel phase of the payment slip and QR-bill in order to<br />
                                    prevent potential rejections and incorrect crediting at the recipient bank if a QR-bill is<br />
                                    mistakenly paid as an orange payment slip.
                                </div>
                            </Grid>
                            <Grid item lg={3} sm={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="qe_reference_check_digit"
                                    label="BESR-ID"
                                    autoComplete="new-password"
                                    // variant="outlined"
                                    color="primary"
                                    inputProps={{
                                        'aria-label': "qe_reference_check_digit",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={<div>
                                        <span style={{ float: 'right' }}> {referenceObj.besr.length + '/ 6'}</span>
                                    </div>}
                                    error={referenceObj.besr.length > 6}
                                    value={referenceObj.besr}
                                    onChange={onChangeBserId}
                                />

                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    id="qe_reference_check_digit"
                                    label="Value *"
                                    autoComplete="new-password"
                                    // variant="outlined"
                                    color="primary"
                                    inputProps={{
                                        'aria-label': "qe_reference_check_digit",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={referenceObj.value}
                                    onChange={onChangeValue}
                                    helperText={<div>
                                        <span style={{ float: 'right' }}> {referenceObj.value.length + `/${(26 - referenceObj.besr.length)}`}</span>
                                    </div>}
                                    error={(referenceObj.value.length + referenceObj.besr.length) > 26}
                                />

                            </Grid>
                            <Grid item lg={3} sm={3} xs={12}>
                                <TextField
                                    fullWidth
                                    id="qe_reference_check_digit"
                                    label="Check  digit"
                                    autoComplete="new-password"
                                    // variant="outlined"
                                    color="primary"
                                    inputProps={{
                                        'aria-label': "qe_reference_check_digit",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={referenceObj.checkdigit}
                                />

                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <div className={classes.showDigit}>
                                    {referenceObj.reference}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color='primary'
                            className={classes.addButton}
                            onClick={onClickInsert}
                        >
                            insert
                        </Button>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            close
            </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default QrReferenceDialog;

// const QrReferenceDialog = () => { };
// export default QrReferenceDialog;
