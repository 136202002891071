import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  furtherInfo: {
    fontSize:"7pt",
    lineHeight:"8pt",
    fontWeight:"bold"
  },
}));

const FurtherInfoSection = () => {
    const classes = useStyles();
      return (
        <div className={classes.furtherInfo}>
         Further Information Section
        </div>
      );
    };
    
    
    export default FurtherInfoSection;
