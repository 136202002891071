const fr = {
    'paymentPart': 'Section paiement',
    'accountPayableTo': 'Compte / Payable à',
    'reference': 'Référence',
    'additionalInformation': 'Informations supplémentaires',
    'furtherInformation': 'Informations additionnelles',
    'currency': 'Monnaie',
    'amount': 'Montant',
    'receipt': 'Récépissé',
    'acceptancePoint': 'Point de dépôt',
    'separateBeforePayingIn': 'A détacher avant le versement',
    'payableBy': 'Payable par',
    'payableByNameOrAddress': 'Payable par (nom/adresse)',
    'inFavourOf': 'En faveur de',
    "paymentInfomation": "Payment information",
    "variant": "Variant",
    "QR-IBAN": "QR-IBAN",
    "unstructuredMsg": "Unstructured message",
    "QR-reference": "QR-reference",
    "CreditorReference": "Creditor reference",
    "payableTo": "Payble to",
    "Name": "Name",
    "Street": "Street",
    "Number": "Number",
    "postalCode": "Postal code",
    "town": "Town",
    "country": "Country",
    "qrIBANInfoTextOfPemitted": "IBAN of the creditor.Fixed length:21, Only IBANs with CH or LI country coded country code permitted.",
    "qrIBANInfoTextOflength": "IBAN or QR-IBAN of the creditor. for ${ibanSpecification.countryCode} length should be: ${ibanSpecification.length}",
    "paymentInfomationHeaderInfoText": "Payment information contains data that is forworded with the payment.",
    "qrVarantInfoText1": "There are three variants of QR-bill:",
    "qrVarantInfoText2": "1). QR-IBAN with QR reference - Replaces the orange payment slip",
    "qrVarantInfoText3": "2). IBAN with Creditor Refernce with - New payment option",
    "qrVarantInfoText4": "3). IBAN without reference - Replaces the red payment slip",    
    "currencyInfoext": "The payment currency. Only CHF, EUR, USD are permitted.",
    "amountInfoText": "The payment amount.The payment element is to be enterd without leading zeros,including decimal seperators and two decimal places. Decimal Maximun 12 digits permitted,including decimal seperators. Only decimal points (.) are permitted as decimal seperators.For a notification,use the amount 0.00.",
    "unStructuredMassageInfoText": "Unstructred information can be used to indicate the payment purpose or for additional texual information about the payments with a structred reference. Unstructred message and Booking instructions may contain a comman total of up to 140 characters.",
    "qrReferenceText": "Some financial institutions recommend using the BESR-ID in the first six digits of the QR reference during the parallel phase of the payment slip and QR-bill in order to prevent potential rejections and incorrect crediting at the recipient bank if a QR-bill is mistakenly paid as an orange payment slip.",
    "creditorNameInfoText": "The creditor's name or company according to the account name.Comment: always matches the account holder. Maximum 70 characters permitted. First name(optional if availabel) + last name or company name.",
    "creditorStreetInfoText": "Street/P.O. Box from creditor's address. Maximum 70 characters permitted.",
    "creditorNumberInfoText": "Building number from creditor's address. 16 characters allowed.",
    "creditorPostalCodeInfoText": "Postal code from creditor's address. Maximum 16 characters permitted. The postal code must be provided without country code prefix.",
    "creditorTownInfoText": "Town from creditor's address. Maximum 35 characters permitted.",
    "crditorCountryInfoText": "Country from creditor's address.",
    "debtorNameInfoText": "The ultimate debtor's name or company.Maximum 70 characters permitted. First name(optional if availabel) + last name or company name.",
    "debtorStreetInfoText": "Street/P.O. Box from ultimate debtor's address. Maximum 70 characters permitted.",
    "debtorNumberInfoText": "Building number from ultimate debtor's address. 16 characters are allowed.",
    "debtorPostalCodeInfoText": "Postal code from ultimate debtor's address. Maximum 16 characters permitted. The postal code must be provided without country code prefix.",
    "debtorTownInfoText": "Town from ultimate debtor's address. Maximum 35 characters permitted.",
    "debtorCountryInfoText": "Country from ultimate debtor's address.",
    "qrrIBANErrorMessage": "Only CH and LI allowed",
    "IBANErrorMessage": "Invalid IBAN",
    "IBANvalidMessage": "Valid IBAN",

    "SCOR": "QR-IBAN mit QR-Referenz (formal ESR-Referenz) (SCOR)",
    "QRR": "IBAN mit Gläubigerreferenz (ISO 11649) (QRR)",
    "NON": "IBAN ohne Referenz (NON)",

    "CHF": "CHF",
    "USD": "USD",
    "EUR": "EUR",


    "referenceTypeInfoText": "The structured reference is either QR reference or an ISO 11649 Creditor reference.Must contain a QR reference is a QR IBAN is used.ISO 11649 Creditor reference can optionaly be entered if the IBAN is used.QR reference: 27 numeric characters.Creditors reference(ISO-11649): max 25 alphanumeric characters.",
    "IBAN": 'IBAN',
    'pageHeaderLine1': 'Generate Your Swiss QR Invoice',
    'pageHeaderLine2': 'A simple and quick way to generate your Swiss QR Code. It\'\s Secure, Compliant and Free!',
    'pageHeaderLine3': 'WE DO NOT STORE ANY OF YOUR PERSONAL DATA FROM THIS TOOL',
    "qrBillPdf": "QR Bill (PDF)",
    "qrBillSvg": "QR Bill (SVG)",
    "qrOnlySvg": "QR Only (SVG)",
    "printQrBill": "Print QR Bill",
    "downloadMasterDataToLocalFile": "Download master data to local file",
    "importFromMasterDataFile": "Import from Master Data file",
    "qrBillLanguage":"QR Bill Language"

}


export default fr;