/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { countriesdata } from './CountryList';
import Avatar from '@material-ui/core/Avatar';
// import {
//   autocompleteCountrySelectorStyle,
//   autocompleteStyle,
// } from '@onelta-styles/utils';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  avatar: {
    borderRadius: 0,
    width: 25,
    height: 20,
    margin: "-2px 5px 5px 5px",
  },
  // autocompleteCountrySelectorStyle,
  // autocompleteStyle,
});

function CountrySelector(props) {
  const classes = useStyles();
  const {
    disableClearable,
    disabled,
    recent,
    value,
    handleChangeCountry,
    index,
    label,
  } = props;
  const [selectedValue, setSelectedValue] = useState('');
  const [recentSelectedCountries, setRecentSelectedCountries] = useState([]);
  const [countries, setCountries] = useState(countriesdata);
  const [optionsdata, setOptions] = useState(null);
  const [recentcountries, setrecentcountries] = useState(null);

  const mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      const A = a[key];
      const B = b[key];
      let indA = order.indexOf(A)
      let indB = order.indexOf(B)

      if (indA == -1)
        indA = order.length - 1
      if (indB == -1)
        indB = order.length - 1

      if (indA < indB) {
        return -1;
      } else if (indA > indB) {
        return 1;
      }
    });
    return array;
  };

  useEffect(() => {
    setSelectedValue(value);
    setCountries(countriesdata);
    if (recent.length) {
      const filterData = countries.filter(country =>
        recent.includes(country.code),
      );
      filterData?.map(opt =>
        recentSelectedCountries?.push({ ...opt, flag: 'recent' })
      )
      setRecentSelectedCountries(
        filterData?.map(opt => {
          return { ...opt, flag: 'recent' }
        }
        )
      )
      const recentUpdated = countries.map(
        obj => recentSelectedCountries?.find(o => o.code === obj.code) || obj,
      );
      const ordered_array = mapOrder(recentUpdated, recent, 'code');

      setCountries(ordered_array);
      setOptions(
        recentUpdated.map((option) => {
          return {
            groupLabels:
              option.flag === 'recent' ? 'Frequently used' : 'All Countries',
            ...option,
          };
        }),
      );
      setRecentSelectedCountries([]);
    }
  }, [recent]);

  const onChangeCountry = (e, newValue) => {
    if (newValue === null || newValue === '') {
      setSelectedValue('');
      handleChangeCountry('', index);
    } else {
      setSelectedValue(newValue.code);
      handleChangeCountry(newValue.code, index);
    }
  }
  return (
    countries &&
    !!optionsdata && (
      <Autocomplete
        // className={
        //   selectedValue && selectedValue !== ''
        //     ? classes.autocompleteCountrySelectorStyle
        //     : classes.autocompleteStyle
        // }
        id="country-select-demo"
        // InputLabelProps={{ shrink: true }}
        disabled={disabled ? disabled : false}
        // disableClearable={disableClearable ? disableClearable : true}
        value={optionsdata.find(
          (item) => item?.code?.toLowerCase() === selectedValue?.toLowerCase(),
        )}
        // style={{ width: 300 }}
        classes={{
          option: classes.option,
        }}
        options={optionsdata.sort(
          (a, b) => -a.groupLabels.localeCompare(b.groupLabels),
        )}
        groupBy={(option) => option.groupLabels}
        autoHighlight
        getOptionLabel={(option) => option.label + ' (' + option.code + ')'}
        // getOptionSelected={(option, value) => option.code === value}
        onChange={onChangeCountry}
        renderOption={(option) => {
          return (
            <React.Fragment>
              <span>
                <Avatar
                  alt="counry"
                  className={classes.avatar}
                  style={{ margin: 5 }}
                  src={`/apps/apptools/img/flags/${option.code.toLowerCase()}.svg`}
                />
              </span>
              {option.label} ({option.code})
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : 'Country'}
            // variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              startAdornment: selectedValue && selectedValue !== '' && (
                <React.Fragment>
                  <Avatar
                    className={classes.avatar}
                    alt="Country"
                    src={`/apps/apptools/img/flags/${selectedValue.toLowerCase()}.svg`}
                  />
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )
  );
}

CountrySelector.propTypes = {
  recent: PropTypes.array,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.any,
  value: PropTypes.any,
  handleChangeCountry: PropTypes.func,
  label: PropTypes.string,
};

export default CountrySelector;

